const actions = {
  SET_STATE: 'coordinations/SET_STATE',
  RESET_STATE: 'coordinations/RESET_STATE',
  SET_PARAMETER_ITEMS: 'coordinations/SET_PARAMETER_ITEMS',
  LOAD_PARAMETER_ITEMS: 'coordinations/LOAD_PARAMETER_ITEMS',
  ADD_PARAMETER_ITEM: 'coordinations/ADD_PARAMETER_ITEM',
  UPDATE_PARAMETER_ITEM: 'coordinations/UPDATE_PARAMETER_ITEM',
  REMOVE_PARAMETER_ITEM: 'coordinations/REMOVE_PARAMETER_ITEM',
}

export default actions
