import localeAntd from 'antd/es/locale/et_EE'

const messages = {
  /**
   * General.
   */
  loading: 'Laadimine',
  email: 'E-post',
  password: 'Parool',
  undefined: 'Määratlemata',
  chooseHere: 'vali siit',
  cancel: 'Tühista',
  'topBar.typeToSearch': 'Otsi...',

  /**
   * Authentication.
   */
  'login.title': 'Tere tulemast Mailabli',
  'login.slogan': 'Korraldage oma meeskonnatööd, jälgige oma töö e-kirju ja nende vastuseid.',
  'login.signInHeader': 'Logi sisse oma kontole',
  'login.btn': 'Logi sisse',
  'login.rules.email': 'Palun sisesta oma e-posti aadress',
  'login.rules.password': 'Palun sisesta oma parool',
  'login.graph.title': 'Viimane samm!',
  'login.graph.slogan':
    'Et Mailabl teie postkastiga töötaks, peame teie kontod omavahel ühendama.\n\nJätkamiseks ühendage oma Office 365 postkasti konto oma Mailabli kontoga.',
  'login.graph.office.account.header': 'Office 365 postkast',
  'login.graph.mailabl.account.header': 'Mailabl konto',
  'login.graph.inProgress': 'Autentimine on pooleli...',
  'login.graph.error':
    'Office 365 autentimise käigus ilmnes viga.\n\nPalun sulge kirjamoodul ja ava see uuesti. Mõnikord võib see aidata.',
  signOut: 'Logi välja',

  /**
   * Taskpane.
   */
  'taskpane.registerMessage': 'Registreeri kiri',
  'taskpane.contact.header': 'Kontakt',
  'taskpane.properties.header': 'Kinnistud',
  'taskpane.tasks.header': 'Ülesanded',
  'taskpane.alreadyRegistered': 'See kiri on juba registreeritud.',
  'taskpane.openExternalLetter': "Avage see Mailabl'is",
  'taskpane.options.header':
    '{email} on seotud mitme sobiva kontaktiga. Seo selle konkreetse kirjaga sobiv.',
  'taskpane.options.btn.assign': 'vali',
  'taskpane.contact.search.title': 'Vali selle konkreetse kirja jaoks sobiv kontakt:',
  'taskpane.contact.noData.noMatches': 'Ühtegi vastet ei leitud päringule',
  'taskpane.contact.noData.youCan': 'Saad kontakti',
  'taskpane.contact.noData.manually': 'Otsida käsitsi',
  'taskpane.contact.noData.or': 'või',
  'taskpane.contact.noData.createContact': 'Lisa uus kontakt',
  'taskpane.contact.create.firstName': 'Eesnimi',
  'taskpane.contact.create.lastName': 'Perekonnanimi',
  'taskpane.contact.create.companyName': 'Ettevõtte nimi',
  'taskpane.contact.create.email': 'E-post',
  'taskpane.contact.create.type.person': 'Eraisik',
  'taskpane.contact.create.type.company': 'Ettevõte',
  'taskpane.contact.create.submit': 'Salvesta',
  'taskpane.pin.message': 'Veendu, kas soovid selle kinnitada tegumiribale',
  'taskpane.pin.description': 'Selliselt ei pea seda iga kirja jaoks uuesti avama.',
  'taskpane.pin.close': 'Sain aru',

  /**
   * Empty.
   */
  empty: 'tühjenda',
  'empty.nothingHereYet': 'Siin pole veel midagi.',

  /**
   * Tasks.
   */
  'tasks.priority.URGENT': 'Kiire',
  'tasks.priority.HIGH': 'Tähtis',
  'tasks.priority.MEDIUM': 'Keskmine',
  'tasks.priority.LOW': 'Madal',

  /**
   * Dialog.
   */
  'dialog.field.correspondenceId': 'Seo kirjaga',
  'dialog.field.status': 'Staatus',
  'dialog.field.summary': 'Kokkuvõte',
  'dialog.field.summary.tooltip': 'Informatiivne kirja sisu koondav lühiülevaade',
  'dialog.field.summary.ai': 'Genereeri AI abiga',
  'dialog.field.type': 'Kirja liik',
  'dialog.field.responsible': 'Vastutaja',
  'dialog.field.members': 'Liikmed',
  'dialog.field.contacts': 'Kontaktid',
  'dialog.field.projects': 'Projektid',
  'dialog.field.properties': 'Kinnistud',
  'dialog.field.tasks': 'Ülesanded',
  'dialog.field.dueAt': 'Tähtaeg',
  'dialog.submit': 'Registreeri',
  'dialog.success.title.processing': 'Töötlemine...',
  'dialog.success.title.success': 'Kiri registreeritud!',
  'dialog.success.title.error': 'Kiri registreeritud, kuid vigadega...',
  'dialog.success.openExternalBtn': "Avage see Mailabl'is",
  'dialog.success.list.data.success': 'Andmed saadetud',
  'dialog.success.list.mime': 'E-kirja lähtefaili üleslaadimine',
  'dialog.success.list.mime.success': 'E-kirja lähtefaili üleslaadimine õnnestus',
  'dialog.success.list.mime.error': 'E-kirja lähtefaili üleslaadimine ebaõnnestus',
  'dialog.success.list.attachments': 'Manuste üleslaadimine',
  'dialog.success.list.attachments.success': 'Manused on üles laetud',
  'dialog.success.list.attachments.error': 'Manuste üleslaadimine ebaõnnestus',
  'dialog.message.wroteOn': 'saatis kirja',
  'dialog.summaryGeneratorFailed':
    'AI-ga kokkuvõtte loomine ebaõnnestus andmete hankimise tõrke tõttu. Palun proovige uuesti või sisestage kokkuvõte käsitsi.',
}

export default {
  locale: 'et',
  datePickerLocale: 'et',
  localeAntd,
  messages,
}
