/* global Office */
import { InteractionType } from '@azure/msal-browser'

export const getOfficeContext = () => Office.context

export const getMailboxContext = () => getOfficeContext().mailbox

export const getItem = () => getMailboxContext()?.initialData

export const getItemId = () => getItem()?.itemId ?? getItem()?.id

export const getUserProfile = () => getMailboxContext()?.userProfile

export const getUserProfileEmailAddress = () => getUserProfile()?.emailAddress

export const getPlatform = () => getOfficeContext().platform

export const isOnlinePlatform = () => getPlatform() === 'OfficeOnline'

export const getInteractionType = () =>
  isOnlinePlatform() ? InteractionType.Popup : InteractionType.Redirect

export const getBody = (item) =>
  item.body.getAsync('html', {}, (result) => (result.status === 'succeeded' ? result.value : null))
