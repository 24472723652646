import { FetchData, RunMutation, RunQuery } from 'services/apollo/helpers'
import { getMainQuery } from 'services/queries/notifications'

export async function fetchMetadata() {
  const response = await RunQuery({
    queryName: 'notificationsMetadata',
    fields: 'unseen unread',
  })

  return response
}

export async function fetchNotifications({ endCursor }) {
  const response = await FetchData({
    endpoint: 'notifications',
    fields: [getMainQuery()],
    after: endCursor,
  })

  return response
}

export async function fetchUnreadNotifications({ endCursor }) {
  const response = await FetchData({
    endpoint: 'unreadNotifications',
    fields: [getMainQuery()],
    after: endCursor,
  })

  return response
}

export async function fetchUnseenNotifications() {
  const response = await FetchData({
    endpoint: 'unseenNotifications',
    fields: [getMainQuery()],
  })

  return response
}

export async function markNotificationsAsSeen() {
  const response = await RunMutation({
    mutationName: 'markNotificationsAsSeen',
    enableQueryFields: false,
    enableParameters: false,
  })

  return response
}

export async function markNotificationsAsRead() {
  const response = await RunMutation({
    mutationName: 'markNotificationsAsRead',
    enableQueryFields: false,
    enableParameters: false,
  })

  return response
}

export async function markNotificationAsRead({ id }) {
  const response = await RunMutation({
    mutationName: 'markNotificationAsRead',
    queryFields: ['readAt'],
    input: id,
    idParameterType: 'ID!',
  })

  return response
}

export async function markNotificationAsUnread({ id }) {
  const response = await RunMutation({
    mutationName: 'markNotificationAsUnread',
    queryFields: ['readAt'],
    input: id,
    idParameterType: 'ID!',
  })

  return response
}
