const actions = {
  SET_STATE: 'tags/SET_STATE',
  RESET_STATE: 'tags/RESET_STATE',
  LOAD_TAGS: 'tags/LOAD_TAGS',
  SET_TAGS: 'tags/SET_TAGS',
  LOAD_MODULES: 'tags/LOAD_MODULES',
  SET_MODULES: 'tags/SET_MODULES',
  ADD_TAG: 'tags/ADD_TAG',
  UPDATE_TAG: 'tags/UPDATE_TAG',
  REMOVE_TAG: 'tags/REMOVE_TAG',
}

export default actions
