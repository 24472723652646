import { createBrowserHistory } from 'history'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createReduxHistoryContext } from 'redux-first-history'
import { createWouterHook } from 'redux-first-history/wouter'
import createSagaMiddleware from 'redux-saga'
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
  withReduxStateSync,
} from 'redux-state-sync'
import dialog from './redux/dialog/reducers'
import letter from './redux/letter/reducers'
import modules from './redux/modules/reducers'
import sagas from './redux/sagas'
import settings from './redux/settings/reducers'
import statuses from './redux/statuses/reducers'
import taskpane from './redux/taskpane/reducers'
import user from './redux/user/reducers'
import users from './redux/users/reducers'

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
})

const sagaMiddleware = createSagaMiddleware()
const middlewares = [
  sagaMiddleware,
  routerMiddleware,
  createStateSyncMiddleware({
    blacklist: ['LOCATION_CHANGE'],
    broadcastChannelOption: { type: 'localstorage' },
  }),
]

const store = createStore(
  withReduxStateSync(
    combineReducers({
      router: routerReducer,
      user,
      settings,
      taskpane,
      letter,
      users,
      dialog,
      statuses,
      modules,
    }),
  ),
  composeWithDevTools(applyMiddleware(...middlewares)),
)

const history = createReduxHistory(store)
const wouterUseLocation = createWouterHook(history)

initStateWithPrevTab(store)

sagaMiddleware.run(sagas)

export { history, store, wouterUseLocation }
