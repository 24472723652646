export const getColumns = () => [
  {
    type: 'child',
    field: 'number',
    width: '11%',
  },
  {
    type: 'child',
    field: 'name',
    width: '49%',
  },
  {
    type: 'child',
    field: 'members',
    subQueryFields: ['edges { node { id displayName } isResponsible }'],
    width: '20%',
  },
  {
    type: 'child',
    field: 'status',
    subQueryFields: ['id', 'name', 'color', 'type'],
    width: '15%',
  },
]

export const getAdditionalQueryFields = () => [
  {
    type: 'child',
    fields: `client { id displayName } description isPublic`,
  },
]

export const getOrderBy = () => [
  {
    column: 'NUMBER',
    order: 'ASC',
  },
]
