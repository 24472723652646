import actions from './actions'

const initialState = []

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return [...state, ...action.payload]

    case actions.SET_USERS:
      return action.payload?.map(({ node: item }) => item)

    case actions.RESET_STATE:
      return initialState

    default:
      return state
  }
}
