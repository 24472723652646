export const getMainQuery = () => `
  id
  status {
    id
    name
    color
    type
  }
  number
  correspondenceNumber
  subject
  summary
  type {
    id
    name
  }
  source {
    id
    name
  }
  direction
  body
  sentAt
  dueAt
  completedAt
  createdAt
  updatedAt
`

export const getConditionalSelectPayload = () => `
  ${getMainQuery()}
`

export const getLettersQuery = () => `
  letters(
    first: 50
    orderBy: [
      {
        column: NUMBER,
        order: DESC
      }
    ]
  ) {
    edges {
      node {
        id
        status {
          id
          name
          color
          type
        }
        number
        subject
        summary
      }
    }
  }
`

export const getCorrespondenceQuery = () => `
  id
  number
  parent {
    id
  }
  type {
    id
  }
  members {
    edges {
      node {
        id
      }
      isResponsible
    }
  }
  contacts {
    edges {
      node {
        id
        displayName
      }
    }
  }
  projects {
    edges {
      node {
        id
        name
      }
    }
  }
  properties {
    edges {
      node {
        id
        displayAddress
      }
    }
  }
  tasks {
    edges {
      node {
        id
        title
      }
    }
  }
`
