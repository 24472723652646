export const getColumns = () => [
  {
    type: 'child',
    field: 'number',
    width: '11%',
  },
  {
    type: 'child',
    field: 'name',
    width: '59%',
  },
  {
    type: 'child',
    field: 'members',
    subQueryFields: ['edges { node { id displayName } }'],
    width: '20%',
  },
  {
    type: 'child',
    field: 'status',
    subQueryFields: ['id', 'name', 'color', 'type'],
    width: '15%',
  },
]

export const getAdditionalQueryFields = () => [
  {
    type: 'child',
    fields: `
      type {
        name
      }
    `,
  },
]

export const getOrderBy = () => [
  {
    column: 'NUMBER',
    order: 'ASC',
  },
]
