import { Client } from '@microsoft/microsoft-graph-client'
import MsalAuthenticationProvider from './provider'

export default (providerOptions) => {
  const clientOptions = {
    authProvider: new MsalAuthenticationProvider(providerOptions),
  }

  return Client.initWithMiddleware(clientOptions)
}
