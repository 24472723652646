export const getMainQuery = () => `
  id
  firstName
  lastName
  displayName
  email
  position
  roles {
    id
    displayName
  }
  deletedAt
`

export const getConditionalSelectPayload = () => `
  ${getMainQuery()}
`
