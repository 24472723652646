import { FetchAllData } from 'services/apollo/helpers'
import { ucFirst } from 'services/helpers/text'

export async function fetchParameterItems(type) {
  const payload = {
    endpoint: `letter${ucFirst(type)}`,
    fields: ['id', 'name'],
  }

  const response = await FetchAllData(payload)

  return response
}

export async function fetchCorrespondence(number) {
  const payload = {
    endpoint: 'correspondences',
    fields: [
      `
        letters {
          __typename
          id
          number
          subject
          summary
          direction
          status {
            id
            name
            color
            type
          }
          sentAt
          members {
            edges {
              node {
                id
                displayName
              }
              isResponsible
            }
          }
        }
      `,
    ],
    where: {
      column: 'CORRESPONDENCE_NUMBER',
      value: number,
    },
    whereConditionsName: 'QueryCorrespondencesWhereWhereConditions',
  }

  const response = await FetchAllData(payload)

  return response
}
