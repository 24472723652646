const actions = {
  SET_STATE: 'entities/SET_STATE',
  RESET_STATE: 'entities/RESET_STATE',
  LOAD_ENTITY: 'entities/LOAD_ENTITY',
  LOAD_ENTITIES: 'entities/LOAD_ENTITIES',
  DELETE_ENTITY: 'entities/DELETE_ENTITY',
  DELETE_SUCCESS: 'entities/DELETE_SUCCESS',
  SET_ENTITY: 'entities/SET_ENTITY',
  SET_ENTITIES: 'entities/SET_ENTITIES',
  UPDATE_ENTITY_VALUE: 'entities/UPDATE_ENTITY_VALUE',
  UPDATE_STATUS: 'entities/UPDATE_STATUS',
  UPDATE_PRIORITY: 'entities/UPDATE_PRIORITY',
}

export default actions
