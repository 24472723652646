import {
  getAdditionalQueryFields as baseGetAdditionalQueryFields,
  getColumns as baseGetColumns,
  getOrderBy as baseGetOrderBy,
} from 'services/middle/contracts'

export const getColumns = () => baseGetColumns()

export const getAdditionalQueryFields = () => [
  ...baseGetAdditionalQueryFields(),
  ...[
    {
      type: 'pivot',
      fields: 'notes',
    },
  ],
]

export const getOrderBy = () => baseGetOrderBy()
