import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import EntitiesActions from 'redux/entities/actions'
import modulesActions from 'redux/modules/actions'
import { getListData } from 'services/list'
import actions from './actions'
import { getTableRows } from './selectors'

export function* deleteEntity({
  payload: { id, endpoint, objectType, showMessage, deleteRow = true, intl, message },
}) {
  yield put({
    type: actions.SET_DELETING,
    payload: id,
  })

  try {
    yield put({
      type: EntitiesActions.DELETE_ENTITY,
      payload: {
        id,
        endpoint,
        objectType,
        showMessage,
        origin: 'list',
        deleteRow,
        intl,
        message,
      },
    })
  } catch (e) {
    yield put({ type: 'DELETE_ENTITY_FAILED', message: e.message })
  }
}

export function* deleteSuccess({ payload: { id, deleteRow } }) {
  yield put({ type: actions.RESET_DELETING })

  const tableRows = yield select(getTableRows)

  if (deleteRow) {
    const updatedRows = tableRows.filter((item) => item.id !== id)

    yield put({
      type: actions.SET_STATE,
      payload: {
        tableRows: updatedRows,
      },
    })
  }
}

export function* updateRow({ payload }) {
  const tableRows = yield select(getTableRows)

  const updatedRows = tableRows?.map((item) => {
    if (item.id === payload.id) {
      return {
        ...item,
        ...payload,
      }
    }

    return item
  })

  yield put({
    type: actions.SET_STATE,
    payload: {
      tableRows: updatedRows,
    },
  })
}

export function* loadListData({ payload: { slug } }) {
  const { fields } = yield select((state) => state.modules?.[slug] || {})
  const data = yield call(getListData, slug, !fields)
  const { moduleFields, views } = data?.data || {}

  if (moduleFields) {
    yield put({
      type: modulesActions.SET_FIELDS,
      payload: {
        module: slug,
        fields: moduleFields,
      },
    })
  }

  if (views) {
    yield put({
      type: actions.SET_SAVED_VIEW,
      payload: views?.edges?.[0]?.node,
    })
  }

  yield put({
    type: actions.SET_LIST_DATA_LOADED,
    payload: true,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.DELETE_ENTITY, deleteEntity),
    takeEvery(actions.DELETE_SUCCESS, deleteSuccess),
    takeEvery(actions.UPDATE_ROW, updateRow),
    takeEvery(actions.LOAD_LIST_DATA, loadListData),
  ])
}
