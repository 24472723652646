export const getAll = ({ list }) => list

export const getSearchTerm = ({ list }) => list.term

export const getAppliedFilters = ({ list }) => list.appliedFilters

export const getActiveFiltersCount = ({ list }) => list.activeFiltersCount

export const getWhereConditions = ({ list }) => list.whereConditions

export const getSorter = ({ list }) => list.sorter

export const getPagination = ({ list }) => list.pagination

export const getIsDeleting = ({ list }) => list.delete.isLoading

export const getIsDeletingId = ({ list }) => list.delete.id

export const getTableRows = ({ list }) => list.tableRows

export const getSavedView = ({ list }) => list.savedView

export const getListDataLoaded = ({ list }) => list.listDataLoaded

export const getFilterableFields = ({ list }) => list.filterableFields

export const getColumns = ({ list }) => list.columns

export const getObjectType = ({ list }) => list.objectType
