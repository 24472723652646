const actions = {
  SET_STATE: 'ordinances/SET_STATE',
  RESET_STATE: 'ordinances/RESET_STATE',
  SET_PARAMETER_ITEMS: 'ordinances/SET_PARAMETER_ITEMS',
  LOAD_PARAMETER_ITEMS: 'ordinances/LOAD_PARAMETER_ITEMS',
  ADD_PARAMETER_ITEM: 'ordinances/ADD_PARAMETER_ITEM',
  UPDATE_PARAMETER_ITEM: 'ordinances/UPDATE_PARAMETER_ITEM',
  REMOVE_PARAMETER_ITEM: 'ordinances/REMOVE_PARAMETER_ITEM',
}

export default actions
