import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { fetchTagModules, fetchTags } from 'services/tags/fetch'
import actions from './actions'

export function* LOAD_TAGS({ payload }) {
  const { objectType } = payload
  const tags = yield call(fetchTags, objectType)

  yield put({
    type: actions.SET_TAGS,
    payload: {
      objectType,
      tags: tags?.map(({ node }) => node),
    },
  })
}

export function* LOAD_MODULES() {
  const modules = yield call(fetchTagModules)

  yield put({
    type: actions.SET_MODULES,
    payload: modules,
  })
}

export function* ADD_TAG({ payload: { data, objectType } }) {
  const prevObjectData = yield select(({ tags }) => tags[objectType])
  const newState = [...[data], ...prevObjectData]

  yield put({
    type: actions.SET_STATE,
    payload: {
      [objectType]: newState,
    },
  })
}

export function* UPDATE_TAG({ payload: { data, objectType, isNew } }) {
  const prevObjectData = yield select(({ tags }) => tags[objectType])
  const prevDataIndex = prevObjectData.findIndex((item) =>
    isNew ? item.id === 'new' : item.id === data.id,
  )
  const newState = [...prevObjectData]

  newState[prevDataIndex] = { ...newState[prevDataIndex], ...data }

  yield put({
    type: actions.SET_STATE,
    payload: {
      [objectType]: newState,
    },
  })
}

export function* REMOVE_TAG({ payload: { id, objectType } }) {
  const prevObjectData = yield select(({ tags }) => tags[objectType])
  const newState = prevObjectData.filter((item) => item.id !== id)

  yield put({
    type: actions.SET_STATE,
    payload: {
      [objectType]: newState,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_TAGS, LOAD_TAGS),
    takeEvery(actions.LOAD_MODULES, LOAD_MODULES),
    takeEvery(actions.ADD_TAG, ADD_TAG),
    takeEvery(actions.UPDATE_TAG, UPDATE_TAG),
    takeEvery(actions.REMOVE_TAG, REMOVE_TAG),
  ])
}
