import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { FetchEntity } from 'services/apollo/helpers'
import { getLetterByImmutableId } from 'services/backend'
import { getImmutableId, getMessage, getMessageDirection } from 'services/graph'
import { getContactsTaskpaneQuery } from 'services/queries/contacts'
import TaskpaneActions from '../taskpane/actions'
import actions from './actions'

export function* ASSIGN_CONTACT({ payload: { letterId, contactId } }) {
  try {
    const currentState = JSON.parse(localStorage.getItem('assignedContacts')) || {}
    const updatedState = {
      ...currentState,
      ...{
        [letterId]: contactId,
      },
    }

    localStorage.setItem('assignedContacts', JSON.stringify(updatedState))

    const contact = yield call(FetchEntity, {
      endpoint: 'contact',
      id: contactId,
      fields: [getContactsTaskpaneQuery()],
    })

    yield put({
      type: TaskpaneActions.SET_CONTACT,
      payload: contact,
    })
  } catch (e) {
    yield put({ type: 'ASSIGN_CONTACT_FAILED', message: e.message })
  }
}

export function* SET_MESSAGE({ payload }) {
  const { id } = payload

  try {
    const graphEmail = yield select(({ user }) => user?.graphData?.mail)

    const immutableId = yield call(getImmutableId, id)
    const message = yield call(getMessage, immutableId)
    const direction = yield call(getMessageDirection, message, graphEmail)
    const externalLetter = yield call(getLetterByImmutableId, immutableId)

    const savePayload = {
      immutableId,
      message,
      direction,
      externalLetter: externalLetter?.data?.[0]?.node || null,
    }

    yield put({
      type: actions.SET_STATE,
      payload: savePayload,
    })
  } catch (e) {
    yield put({ type: 'SET_MESSAGE_FAILED', message: e.message })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_MESSAGE, SET_MESSAGE),
    takeEvery(actions.ASSIGN_CONTACT, ASSIGN_CONTACT),
  ])
}
