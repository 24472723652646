import { gql } from '@apollo/client'
import ViewActions from 'redux/view/actions'
import apiClient from 'services/apollo'
import { getRangePresets } from 'services/date'
import { getFieldByName, getFieldSettings } from 'services/fields'
import { getMessageText } from 'services/helpers/message'
import { ucFirst } from 'services/helpers/text'
import { getQueryFields } from 'services/middle'
import { getOrderBy as tasksGetOrderBy } from 'services/middle/tasks'

/**
 * Prepare a query addon for the sorting portion.
 */
export function getSorterVariableValue(
  objectType,
  field = null,
  order,
  fields = [],
  defaultOrderBy,
) {
  if (!field && objectType === 'tasks' && defaultOrderBy === null) {
    return tasksGetOrderBy()
  }

  // When no field specified use the main field
  if (!field) {
    if (defaultOrderBy) {
      field = defaultOrderBy
    } else if (!fields?.length) {
      field = fields.filter((item) => item.isMainField)?.[0]?.name
    }
  }

  const enumName = getFieldByName(fields, field)?.enum || 'ID'

  const orders = {
    ascend: 'ASC',
    descend: 'DESC',
  }

  if (!order && objectType === 'letters') {
    order = 'descend'
  }

  return [
    {
      column: enumName,
      order: orders[order || 'ascend'],
    },
  ]
}

/**
 * Parse the filter operator for GraphQL
 */
export function parseFilterOperatorForGraphQL(operator) {
  const mapping = {
    contains: 'LIKE',
    doesNotContain: 'NOT_LIKE',
    beginsWith: 'LIKE',
    endsWith: 'LIKE',
    isSet: 'IS_NOT_NULL',
    isNotSet: 'IS_NULL',
    eq: 'EQ',
    neq: 'NEQ',
    gt: 'GT',
    lt: 'LT',
    gte: 'GTE',
    lte: 'LTE',
    is: 'IN',
    isNot: 'NOT_IN',
    dateBetween: 'BETWEEN',
    between: 'BETWEEN',
    notBetween: 'NOT_BETWEEN',
  }

  return mapping[operator]
}

export function parseRangePickerValueForGraphQL(value) {
  const ranges = getRangePresets()

  let presetValue = null

  // eslint-disable-next-line array-callback-return
  Object.values(ranges).filter((subRanges) => {
    // eslint-disable-next-line array-callback-return
    Object.entries(subRanges).filter(([label, values]) => {
      if (label === value) {
        presetValue = values
      }
    })
  })

  return presetValue
}

/**
 * Parse the filter value for GraphQL.
 */
export function parseFilterValueForGraphQL(value, operator) {
  if (['contains', 'doesNotContain'].includes(operator)) {
    return `%${value}%`
  }

  if (operator === 'beginsWith') {
    return `${value}%`
  }

  if (operator === 'endsWith') {
    return `%${value}`
  }

  if (['isSet', 'isNotSet'].includes(operator)) {
    return null
  }

  if (['is', 'isNot'].includes(operator) && !Array.isArray(value)) {
    return [value]
  }

  if (operator === 'dateBetween' && typeof value === 'string') {
    return parseRangePickerValueForGraphQL(value)
  }

  return value
}

/**
 * Prepare the where/filter conditions.
 */
export function getQueryWhereConditions(filters = null, fields) {
  if (!filters) return null

  const conditions = {
    where: [],
    relations: [],
  }

  filters?.map((item) => {
    const field = getFieldByName(fields, item.field)
    const fieldSettings = getFieldSettings(field)
    const useHas = fieldSettings?.filter?.useHas

    // eslint-disable-next-line prefer-const
    let itemCondition = {
      column: field.enum,
      operator: parseFilterOperatorForGraphQL(item.operator),
    }

    // If the input field is also present include that in the condition
    if (item.value) {
      const parsedValue = parseFilterValueForGraphQL(item.value, item.operator)

      if (parsedValue) {
        itemCondition.value = parsedValue
      }
    }

    if (itemCondition.operator === 'NEQ') {
      itemCondition = {
        OR: [
          { ...itemCondition },
          {
            column: itemCondition.column,
            operator: 'IS_NULL',
          },
        ],
      }
    }

    if (field.type === 'CHECKBOX' && !itemCondition.value) {
      itemCondition.value = false
    }

    if (field.type === 'PRIORITY' && !itemCondition.value) {
      itemCondition.operator = 'IS_NULL'
    }

    if (field.type === 'CHECKBOX' && field.name === 'isParent') {
      itemCondition.operator = itemCondition.value ? 'IS_NULL' : 'IS_NOT_NULL'
      delete itemCondition.value
    }

    if (field.type === 'RELATION' && useHas) {
      let conditionString = ''

      if (itemCondition.operator === 'IS_NULL') {
        conditionString = `relationIsAbsent: ${itemCondition.column}`
      } else {
        // eslint-disable-next-line array-callback-return
        Object.keys(itemCondition)?.map((key) => {
          let value = itemCondition[key]

          // Overwrite the queryable field's name with regular "id" field
          if (key === 'column') {
            value = 'ID'
          }

          // Convert the value from array to string
          if (key === 'value') {
            value = `[${Array.isArray(value) ? value.join(',') : value}]`
          }

          conditionString += `${key}: ${value}\n`
        })

        // Wrap the condition with the relation name
        conditionString = `has${ucFirst(field.name)}: {\n
          ${conditionString}
        }`
      }

      // Append the current condition to the relations conditions
      conditions.relations.push(conditionString)
    } else {
      // Append the current condition to the overall conditions
      conditions.where.push(itemCondition)
    }

    return null
  })

  if (conditions.where.length) {
    conditions.where = { AND: conditions.where }
  }

  return conditions
}

/**
 * Reformat the fields onto separate lines.
 */
export function separateFieldsOnLines(fields = []) {
  return fields?.map((field) => {
    if (typeof field === 'object') {
      return `
        ${Object.keys(field)} {
          ${Object.values(field).join('\n')}
        }
      `
    }

    return field
  })
}

/**
 * Parse query parameters.
 */
export function parseParameters(parameters) {
  const formatted = {
    parent: [],
    child: [],
  }

  Object.keys(parameters)?.forEach((key) => {
    const value = parameters[key]

    formatted.parent.push(`$${key}: ${value}`)
    formatted.child.push(`${key}: $${key}`)
  })

  return formatted
}

/**
 * Update an entity's values.
 */
export async function UpdateValues({ endpoint, id, payload, queryFields }) {
  const mutationName = `update${ucFirst(endpoint)}`

  // Prepare the input variable
  const input = {
    ...{ id },
    ...payload,
  }

  // Prepare the variables parameter
  const variables = {
    input,
  }

  if (!queryFields) {
    queryFields = separateFieldsOnLines(Object.keys(variables.input))
  }

  // Prepare the mutation
  const mutation = gql`
    mutation ${ucFirst(mutationName)}(
      $input: Update${ucFirst(endpoint)}Input!
    ) {
      ${mutationName}(input: $input) {
        __typename
        ${queryFields}
      }
    }
  `

  // Execute the mutation
  const response = await apiClient.mutate({
    mutation,
    variables,
  })

  // Await for the response to resolve
  const data = await response.data[mutationName]

  return data
}

/**
 * Delete an entity.
 */
export async function DeleteEntity({ endpoint, id }) {
  const mutationName = `delete${ucFirst(endpoint)}`

  // Prepare the variables parameter
  const variables = {
    id,
  }

  // Prepare the mutation
  const mutation = gql`
    mutation ${ucFirst(mutationName)}(
      $id: ID!
    ) {
      ${mutationName}(id: $id) {
        __typename
        deletedAt
      }
    }
  `

  // Execute the mutation
  const response = await apiClient.mutate({
    mutation,
    variables,
  })

  // Await for the response to resolve
  const data = await response.data[mutationName]

  return data.deletedAt !== null
}

/**
 * Save a relationship.
 */
export async function SaveRelationship({
  endpoint,
  id,
  relation,
  relatedId,
  values,
  dispatch,
  helpersPath,
  updateExistingRow = false,
  intl,
  message,
}) {
  const helpersFilePath = helpersPath || `${endpoint}/${relation}`
  const relationQueryFields = getQueryFields(helpersFilePath)
  const isSingleValue = typeof values === 'string'
  const action = 'syncWithoutDetaching'

  const relationQueryConditions = `
    where: {
      column: ID,
      operator: ${isSingleValue ? 'EQ' : 'IN'}
      value: ${isSingleValue ? relatedId : `[${relatedId}]`}
    }
  `

  const queryFields = `
    id
    ${relation} (
      ${relationQueryConditions}
    ) {
      edges {
        node {
          __typename
          id
          ${relationQueryFields.child}
        }
        ${relationQueryFields.pivot}
      }
    }
  `

  const payload = {
    [relation]: {
      [action]: isSingleValue ? [values] : values,
    },
  }

  const response = await UpdateValues({
    endpoint,
    id,
    payload,
    queryFields,
  })

  if (response.id) {
    const edges = response?.[relation]?.edges

    if (updateExistingRow) {
      dispatch({
        type: ViewActions.UPDATE_RELATION_ROW,
        payload: {
          relation,
          id: relatedId,
          row: { ...edges[0], ...{ isEditing: undefined } },
          intl,
          message,
        },
      })
    } else {
      edges?.forEach((item) => {
        dispatch({
          type: ViewActions.SET_RELATION_FIRST_ROW,
          payload: {
            relation,
            row: item,
          },
        })
      })
    }

    message.success(
      getMessageText({
        action,
        text: `relation.${relation}`,
        intl,
      }),
    )
  }

  return response
}

/**
 * Delete a relationship.
 */
export async function DeleteRelationship({ mainEndpoint, mainId, relationship, relatedId }) {
  const mutationName = `update${ucFirst(mainEndpoint)}`

  // Prepare the input variable
  const input = {
    id: mainId,
    [relationship]: {
      disconnect: [relatedId],
    },
  }

  // Prepare the variables parameter
  const variables = {
    input,
  }

  // Prepare the mutation
  const mutation = gql`
    mutation ${ucFirst(mutationName)}(
      $input: ${ucFirst(mutationName)}Input!
    ) {
      ${mutationName} (
        input: $input
      ) {
        __typename
        id
      }
    }
  `

  // Execute the mutation
  const response = await apiClient.mutate({
    mutation,
    variables,
  })

  // Await for the response to resolve
  const data = await response.data[mutationName]

  return data
}

/**
 * Run query.
 */
export async function RunQuery({ queryName, fields, parameters, variables }) {
  const formattedParameters = parameters ? parseParameters(parameters) : {}

  const subSelection = fields
    ? `
      {
        __typename
        ${fields}
      }
    `
    : ''

  const query = gql`
    query ${ucFirst(queryName)}${
    formattedParameters?.parent ? `(${formattedParameters?.parent})` : ''
  } {
      ${queryName}${
    formattedParameters?.child ? `(${formattedParameters?.child})` : ''
  } ${subSelection}
    }
  `

  // Execute the query
  try {
    const response = await apiClient.query({
      query,
      variables,
    })

    // Await for the response to resolve
    const data = await response.data[queryName]

    return data
  } catch (e) {
    return false
  }
}

/**
 * Run mutation.
 */
export async function RunMutation({
  mutationName,
  input,
  inputName,
  queryFields,
  enableQueryFields = true,
  idParameter = 'id',
  idParameterType = 'ID',
  enableParameters = true,
  dispatch,
}) {
  // Prepare the variables parameter
  let variables = {
    input,
  }

  // If no fields are set we can assume that at least the ID field should be returned
  if (!queryFields) {
    queryFields = `
      id
    `
  }

  const queryFieldsPart = enableQueryFields
    ? `{
      __typename
      ${queryFields}
    }`
    : ''

  // Prepare the mutation
  let mutation = gql`
    mutation ${ucFirst(mutationName)}(
      $input: ${inputName}!
    ) {
      ${mutationName}(input: $input) ${queryFieldsPart}
    }
  `

  // If a input name is not provided we'll assume the role of just regular id field as the payload
  if (!inputName) {
    variables = {
      [idParameter]: input,
    }

    let queryParametersTypePart = `($${idParameter}: ${idParameterType})`
    let queryParametersPart = `(${idParameter}: $${idParameter})`

    if (!enableParameters) {
      queryParametersTypePart = ''
      queryParametersPart = ''
    }

    mutation = gql`
      mutation ${ucFirst(mutationName)} ${queryParametersTypePart} {
        ${mutationName} ${queryParametersPart} ${queryFieldsPart}
      }
    `
  }

  // Execute the mutation
  try {
    const response = await apiClient.mutate({
      mutation,
      variables,
    })

    // Await for the response to resolve
    const data = await response.data[mutationName]

    return data
  } catch (e) {
    if (typeof dispatch !== 'undefined') {
      dispatch({
        type: 'create/SET_IS_SAVING',
        payload: false,
      })
    }

    return false
  }
}

/**
 * Update entity status.
 */
export async function UpdateStatus({ endpoint, id, status }) {
  const mutationName = `update${ucFirst(endpoint)}`
  const inputName = `Update${ucFirst(endpoint)}Input`

  const input = {
    id,
    status,
  }

  const queryFields = `
    id
    status {
      id
      name
      color
    }
  `

  const response = await RunMutation({
    mutationName,
    input,
    inputName,
    queryFields,
  })

  return response
}

/**
 * Update entity priority.
 */
export async function UpdatePriority({ endpoint, id, priority }) {
  const mutationName = `update${ucFirst(endpoint)}`
  const inputName = `Update${ucFirst(endpoint)}Input`

  const input = {
    id,
    priority,
  }

  const queryFields = `
    id
    priority
  `

  const response = await RunMutation({
    mutationName,
    input,
    inputName,
    queryFields,
  })

  return response
}

/**
 * Get a list of existing relations between 2 entities.
 */
export async function GetExistingRelations({ parent, parentId, child }) {
  const queryName = 'getExistingRelations'

  const parameters = {
    parent: 'String!',
    parentId: 'ID!',
    child: 'String!',
  }

  const variables = {
    parent,
    parentId,
    child,
  }

  const response = await RunQuery({
    queryName,
    parameters,
    variables,
  })

  return response
}

/**
 * Fetch entity.
 */
export async function FetchEntity({ endpoint, id, fields }) {
  if (!id) return null

  // If the fields is an array transform it to a string
  if (Array.isArray(fields) === true) {
    fields = fields.join('\n')
  }

  // Prepare the query
  const query = gql`
    query ${endpoint}($id: ID!) {
      ${endpoint}(id: $id) {
        __typename
        ${fields}
      }
    }
  `

  // Prepare the variables parameter
  const variables = {
    id,
  }

  // Query for the fields
  const response = await apiClient.query({
    query,
    variables,
  })

  // Await for the response to resolve
  const data = await response.data[endpoint]

  return data
}

/**
 * Fetch data from the backend.
 */
export async function FetchData({
  endpoint,
  first = 10,
  after = null,
  fields = [],
  params = [],
  where,
  searchTerm = null,
  whereConditionsName = '',
  orderBy = [],
  orderByConditionsName = '',
  hasConditions = [],
}) {
  let queryParameters = `
    $first: Int,
    $after: String,
  `

  let paginatorConditions = `
    first: $first,
    after: $after,
  `

  let variables = {
    first,
    after,
  }

  if (params.length) {
    // eslint-disable-next-line array-callback-return
    params.map(({ field, value, type }) => {
      queryParameters = `
        ${queryParameters}
        $${field}: ${type},
      `

      paginatorConditions = `
        ${paginatorConditions}
        ${field}: $${field},
      `

      if (value) {
        variables = { ...variables, ...{ [field]: value } }
      }
    })
  }

  if (hasConditions.length) {
    // eslint-disable-next-line array-callback-return
    hasConditions.map(({ field, value }) => {
      paginatorConditions = `
        ${paginatorConditions}
        ${field}: ${value}
      `
    })
  }

  if (whereConditionsName && where) {
    queryParameters = `
      ${queryParameters}
      $where: ${whereConditionsName}
    `

    paginatorConditions = `
      ${paginatorConditions}
      where: $where,
    `

    variables = {
      ...variables,
      ...{
        where,
      },
    }
  }

  if (searchTerm) {
    queryParameters = `
      ${queryParameters}
      $search: String
    `

    paginatorConditions = `
      ${paginatorConditions}
      search: $search,
    `

    variables = {
      ...variables,
      ...{
        search: searchTerm,
      },
    }
  }

  if (orderByConditionsName && orderBy) {
    queryParameters = `
      ${queryParameters}
      $orderBy: ${orderByConditionsName}
    `

    paginatorConditions = `
      ${paginatorConditions}
      orderBy: $orderBy,
    `

    variables = {
      ...variables,
      ...{
        orderBy,
      },
    }
  }

  // Prepare the query
  const query = gql`
    query ${endpoint}(${queryParameters}) {
      ${endpoint} (${paginatorConditions}) {
        pageInfo {
          hasNextPage
          endCursor
          currentPage
          total
        }
        edges {
          node {
            __typename
            ${fields.join('\n')}
          }
        }
      }
    }
  `

  // Query for the fields
  const response = await apiClient.query({
    query,
    variables,
  })

  // Await for the response to resolve
  const data = await response.data[endpoint]?.edges
  const paginationData = await response.data[endpoint].pageInfo

  return {
    data,
    paginationData,
  }
}

/**
 * Fetch all of the data from the backend matching our query, including all pages.
 */
export async function FetchAllData(props) {
  let allResults = []

  const fetchAll = async () => {
    let hasNextPage = true

    while (hasNextPage) {
      // eslint-disable-next-line no-await-in-loop
      const response = await FetchData(props)

      const { data, paginationData } = response

      // Merge current results with the previous batch
      allResults = [...allResults, ...data]

      // Determine whether we have more pages
      hasNextPage = paginationData?.hasNextPage

      // Update the next page number
      props.after = paginationData?.endCursor
    }
  }

  await fetchAll()

  return allResults
}
