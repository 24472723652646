import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import {
  fetchMetadata,
  fetchNotifications,
  fetchUnreadNotifications,
  fetchUnseenNotifications,
  markNotificationAsRead,
  markNotificationAsUnread,
  markNotificationsAsRead,
  markNotificationsAsSeen,
} from 'services/notifications'
import actions from './actions'
import { getEndCursor, getRows, getUnseen, getUnseenRows } from './selectors'

export function* LOAD_METADATA() {
  const response = yield call(fetchMetadata)

  yield put({
    type: actions.SET_METADATA,
    payload: response,
  })
}

export function* MARK_SEEN() {
  const unseenCount = yield select(getUnseen)

  if (unseenCount) {
    const response = yield call(markNotificationsAsSeen)

    if (response) {
      yield put({
        type: actions.SET_UNSEEN,
        payload: 0,
      })
    }
  }
}

export function* MARK_ALL_AS_READ() {
  const response = yield call(markNotificationsAsRead)

  if (response) {
    const rows = yield select(getRows)
    const currentTimestamp = new Date()

    const updatedRows = [...rows]?.map((item) => {
      if (item.readAt === null) {
        item.readAt = currentTimestamp
      }

      return item
    })

    yield put({
      type: actions.SET_ROWS,
      payload: updatedRows,
    })
  }
}

export function* UPDATE_ROW({ payload }) {
  const tableRows = yield select(getRows)

  const updatedRows = tableRows?.map((item) => {
    if (item.id === payload.id) {
      return {
        ...item,
        ...payload,
      }
    }

    return item
  })

  yield put({
    type: actions.SET_STATE,
    payload: {
      rows: updatedRows,
    },
  })
}

export function* UPDATE_UNSEEN_ROW({ payload }) {
  const tableRows = yield select(getUnseenRows)

  const updatedRows = tableRows?.map((item) => {
    if (item.id === payload.id) {
      return {
        ...item,
        ...payload,
      }
    }

    return item
  })

  yield put({
    type: actions.SET_STATE,
    payload: {
      unseenRows: updatedRows,
    },
  })
}

export function* MARK_AS_READ({ payload: { id, isUnseenList } }) {
  const response = yield call(markNotificationAsRead, { id })

  if (response) {
    yield put({
      type: isUnseenList ? actions.UPDATE_UNSEEN_ROW : actions.UPDATE_ROW,
      payload: {
        id,
        readAt: response.readAt,
      },
    })
  }
}

export function* MARK_AS_UNREAD({ payload: { id, isUnseenList } }) {
  const response = yield call(markNotificationAsUnread, { id })

  if (response) {
    yield put({
      type: isUnseenList ? actions.UPDATE_UNSEEN_ROW : actions.UPDATE_ROW,
      payload: {
        id,
        readAt: response.readAt,
      },
    })
  }
}

export function* LOAD_DATA({ payload: { type, resetState } }) {
  yield put({
    type: actions.SET_LOADING,
    payload: true,
  })

  if (resetState) {
    yield put({
      type: actions.RESET_ROWS_STATE,
    })
  }

  const endCursor = yield select(getEndCursor)
  const response =
    type === 'unread'
      ? yield call(fetchUnreadNotifications, {
          endCursor,
        })
      : yield call(fetchNotifications, {
          endCursor,
        })

  if (response.data) {
    yield put({
      type: actions.APPEND_ROWS,
      payload: response.data?.map(({ node: item }) => item),
    })

    yield put({
      type: actions.SET_LOADING,
      payload: false,
    })

    yield put({
      type: actions.SET_PAGINATION,
      payload: response.paginationData,
    })
  }
}

export function* RELOAD_UNSEEN_NOTIFICATIONS() {
  yield put({
    type: actions.SET_UNSEEN_ROWS,
    payload: null,
  })

  const response = yield call(fetchUnseenNotifications)

  if (response.data) {
    yield put({
      type: actions.SET_UNSEEN_ROWS,
      payload: response.data?.map(({ node: item }) => item),
    })

    yield put({
      type: actions.SET_UNSEEN_PAGINATION,
      payload: response.paginationData,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_METADATA, LOAD_METADATA),
    takeEvery(actions.MARK_SEEN, MARK_SEEN),
    takeEvery(actions.MARK_ALL_AS_READ, MARK_ALL_AS_READ),
    takeEvery(actions.MARK_AS_READ, MARK_AS_READ),
    takeEvery(actions.MARK_AS_UNREAD, MARK_AS_UNREAD),
    takeEvery(actions.LOAD_DATA, LOAD_DATA),
    takeEvery(actions.UPDATE_ROW, UPDATE_ROW),
    takeEvery(actions.UPDATE_UNSEEN_ROW, UPDATE_UNSEEN_ROW),
    takeEvery(actions.RELOAD_UNSEEN_NOTIFICATIONS, RELOAD_UNSEEN_NOTIFICATIONS),
  ])
}
