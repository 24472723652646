export const getColumns = () => [
  {
    type: 'child',
    field: 'number',
    width: '11%',
  },
  {
    type: 'child',
    field: 'name',
    width: '39%',
  },
  {
    type: 'child',
    field: 'sum',
    subQueryFields: ['amount', 'currencyCode'],
    align: 'right',
    width: '15%',
  },
  {
    type: 'child',
    field: 'dueAt',
    width: '15%',
  },
  {
    type: 'child',
    field: 'status',
    subQueryFields: ['id', 'name', 'color', 'type'],
    width: '15%',
  },
]

export const getAdditionalQueryFields = () => [
  {
    type: 'child',
    fields: `
      type {
        name
      }
    `,
  },
]

export const getOrderBy = () => [
  {
    column: 'NUMBER',
    order: 'ASC',
  },
]
