export const pluckUnique = (array, key) => Array.from(new Set(array?.map((obj) => obj[key])))

export const isValidJson = (data) => {
  try {
    JSON.parse(data)
    return true
  } catch {
    return false
  }
}
