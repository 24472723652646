import { Ability, AbilityBuilder } from '@casl/ability'

const { build } = new AbilityBuilder(Ability)

const ability = build({
  // eslint-disable-next-line no-underscore-dangle
  detectSubjectType: (props) => (typeof props === 'string' ? props : props.__typename),
})

export default ability
