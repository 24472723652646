import * as Sentry from '@sentry/react'
import { notification } from 'antd'
import { camelCase, toLower } from 'lodash'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import moduleActions from 'redux/modules/actions'
import usersActions from 'redux/users/actions'
import ability from 'services/abilities'
import { getMe } from 'services/graph'
import { isValidJson } from 'services/helpers/array'
import * as jwt from 'services/jwt'
import { history } from 'store.js'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    login: jwt.login,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

function parseModulesForMenu(modules) {
  return modules?.map((module) => {
    const slug = toLower(module.slug)

    const data = {
      title: module.title,
      key: slug,
      icon: module.icon,
      url: `/${slug}`,
      hasSingleView: module.hasSingleView,
      hasStatus: module.hasStatus,
      inMenu: module.inMenu,
    }

    if (module.children) {
      const childrenData = module.children?.map((subModule) => {
        const subSlug = camelCase(toLower(subModule.slug))

        return {
          title: subModule.title,
          key: subSlug,
          icon: subModule.icon,
          url: `/${slug}/${subSlug}`,
          hasSingleView: subModule.hasSingleView,
          hasStatus: subModule.hasStatus,
          inMenu: subModule.inMenu,
        }
      })

      data.children = childrenData
    }

    return data
  })
}

function* setUsersData(payload) {
  if (isValidJson(payload)) {
    throw new Error('Users payload faulty')
  }

  yield put({ type: usersActions.SET_USERS, payload })
}

function* setUserState(response) {
  if (response) {
    const { authenticated, users } = response

    const {
      id,
      email,
      firstName,
      lastName,
      isAccountOwner,
      language,
      account,
      abilities,
      modules,
      settingTaskpanePinSeen,
      settingDefaultStatus,
      settingDefaultDeadline,
    } = response.me

    yield put({
      type: 'menu/SET_STATE',
      payload: {
        menuData: parseModulesForMenu(modules),
      },
    })

    yield put({
      type: actions.SET_ABILITIES,
      payload: abilities,
    })

    yield put({
      type: 'settings/CHANGE_SETTING_BULK',
      payload: {
        locale: language,
        taskpanePinSeen: settingTaskpanePinSeen.value === '1',
        defaultStatus: settingDefaultStatus.value,
        defaultDeadline: settingDefaultDeadline.value,
      },
    })

    yield put({
      type: actions.SET_STATE,
      payload: {
        id,
        firstName,
        lastName,
        email,
        authorized: authenticated,
        authorizationChecked: true,
        isAccountOwner,
        modules,
        account,
        loading: false,
      },
    })

    yield all(
      modules?.map((module) =>
        put({
          type: moduleActions.UPDATE_MODULE,
          payload: {
            module: toLower(module.slug),
            data: module,
          },
        }),
      ),
    )

    if (authenticated) {
      yield call(setUsersData, users?.edges)

      Sentry.setUser({ id, email })
    }
  }
}

export function* LOGIN({ payload }) {
  const { email, password, message } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const { authProvider: authProviderName } = yield select((state) => state.settings)
  const success = yield call(mapAuthProviders[authProviderName].login, email, password, message)

  if (success) {
    yield put({
      type: actions.LOAD_CURRENT_ACCOUNT,
    })

    yield history.push('/')

    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in!',
    })
  }

  if (!success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        authorizationChecked: true,
      },
    })
  }
}

export function* LOAD_GRAPH_DATA() {
  const response = yield call(getMe)

  yield put({
    type: actions.SET_STATE,
    payload: {
      graphData: response,
    },
  })
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const { authProvider } = yield select((state) => state.settings)
  const response = yield call(mapAuthProviders[authProvider].currentAccount)

  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        authorizationChecked: true,
      },
    })
  } else {
    yield call(setUserState, response)
  }
}

export function* LOGOUT() {
  const { authProvider } = yield select((state) => state.settings)

  yield call(mapAuthProviders[authProvider].logout)

  yield put({
    type: 'auth/LOGOUT',
  })

  yield put({
    type: actions.RESET_STATE,
  })

  yield put({
    type: usersActions.RESET_STATE,
  })

  yield put({
    type: 'settings/RESET_STATE',
  })

  yield put({
    type: 'letter/RESET_STATE',
  })

  yield put({
    type: 'taskpane/RESET_STATE',
  })
}

export function* SET_ABILITIES({ payload }) {
  yield ability.update(JSON.parse(payload))
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.SET_ABILITIES, SET_ABILITIES),
    takeEvery(actions.LOAD_GRAPH_DATA, LOAD_GRAPH_DATA),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
