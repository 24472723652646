import { cloneDeepWith, noop } from 'lodash'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { fetchComments } from 'services/comments/fetch'
import { getMessageText } from 'services/helpers/message'
import actions from './actions'
import {
  getComments,
  getEditableRelationRow,
  getEndpoint,
  getId,
  getRelationPagination,
  getRelationRows,
} from './selectors'

export function* SET_ID({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      id: payload,
    },
  })
}

export function* SET_FIELDS_LOADED() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      fieldsLoaded: true,
    },
  })
}

export function* SET_DATA_LOADED() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      dataLoaded: true,
    },
  })
}

export function* SET_ENTITY_MISSING() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      entityMissing: true,
    },
  })
}

export function* SET_ENTITIES_MISSING() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      entitiesMissing: true,
    },
  })
}

export function* SET_OBJECT_TYPE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      objectType: payload,
    },
  })
}

export function* SET_ENDPOINT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      endpoint: payload,
    },
  })
}

export function* UPDATE_ENTITY_VALUE({ payload }) {
  const { id, objectType } = yield select(({ view }) => view)

  yield put({
    type: 'entities/UPDATE_ENTITY_VALUE',
    payload: {
      data: payload,
      id,
      objectType,
    },
  })
}

export function* SET_RELATION_ROWS({ payload: { relation, rows, idField = 'id' } }) {
  const relationData = yield select(({ view }) => view.relations?.[relation])
  const { tableRows } = relationData || {}
  const uniqueRows = [...(tableRows || [])]

  rows.forEach((item) => {
    // Check whether such an ID already exists
    const existing = uniqueRows.filter((v) => v.node[idField] === item.node[idField])

    // If not add it to the list
    if (!existing.length) {
      uniqueRows.push(item)
    }
  })

  yield put({
    type: actions.SET_RELATION_DATA,
    payload: {
      relation,
      data: {
        tableRows: uniqueRows,
      },
    },
  })
}

export function* UPDATE_RELATION_ROW({
  payload: { relation, id, row, intl, message, triggerMessage = false, idField = 'id' },
}) {
  const tableRows = yield select(getRelationRows(relation))
  const prevDataIndex = tableRows.findIndex((item) => item.node[idField] === id)
  const updatedRows = [...tableRows]

  updatedRows[prevDataIndex] = { ...updatedRows[prevDataIndex], ...row }

  yield put({
    type: actions.SET_RELATION_DATA,
    payload: {
      relation,
      data: {
        tableRows: updatedRows,
      },
    },
  })

  if (triggerMessage) {
    message.success(
      getMessageText({
        text: 'relation',
        action: 'update',
        intl,
      }),
    )
  }
}

export function* TOGGLE_EDITABLE_RELATION_ROW({ payload: { relation } }) {
  const row = yield select(getEditableRelationRow(relation))

  if (row?.node.id) {
    yield put({
      type: actions.UPDATE_RELATION_ROW,
      payload: {
        relation,
        id: row.node.id,
        row: { ...row, ...{ isEditing: undefined } },
      },
    })
  }
}

export function* DELETE_RELATION_ROW({ payload: { relation, id, idField = 'id', intl, message } }) {
  const tableRows = yield select(getRelationRows(relation))
  const pagination = yield select(getRelationPagination(relation))

  const updatedRows = tableRows.filter((item) => item.node[idField] !== id)

  yield put({
    type: actions.SET_RELATION_DATA,
    payload: {
      relation,
      data: {
        tableRows: updatedRows,
      },
    },
  })

  yield put({
    type: actions.SET_RELATION_PAGINATION,
    payload: {
      relation,
      data: {
        ...pagination,
        total: pagination?.total ? pagination.total - 1 : 0,
      },
    },
  })

  message.success(
    getMessageText({
      text: 'relation',
      action: 'delete',
      intl,
    }),
  )
}

export function* LOAD_COMMENTS() {
  const endpoint = yield select(getEndpoint)
  const id = yield select(getId)

  try {
    const comments = yield call(fetchComments, endpoint, id)

    yield put({
      type: actions.SET_STATE,
      payload: {
        comments,
        commentsLoaded: true,
      },
    })
  } catch (e) {
    yield put({ type: 'LOAD_COMMENTS_FAILED', message: e.message })
  }
}

export function* APPEND_COMMENT_REPLY({ payload: { item } }) {
  const comments = yield select(getComments)

  const result = comments.filter((comment) => comment.id === item.id)?.[0]

  if (result) {
    result.children.push({
      isNew: true,
      parentId: item.id,
    })

    yield put({
      type: actions.SET_STATE,
      payload: {
        comments: [...comments],
      },
    })
  }
}

export function* RESET_COMMENT_REPLY() {
  const comments = yield select(getComments)

  const result = cloneDeepWith(comments, (value) => (value?.isNew === true ? null : noop()))

  yield put({
    type: actions.SET_STATE,
    payload: {
      comments: result,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_ID, SET_ID),
    takeEvery(actions.SET_FIELDS_LOADED, SET_FIELDS_LOADED),
    takeEvery(actions.SET_DATA_LOADED, SET_DATA_LOADED),
    takeEvery(actions.SET_ENTITY_MISSING, SET_ENTITY_MISSING),
    takeEvery(actions.SET_ENTITIES_MISSING, SET_ENTITIES_MISSING),
    takeEvery(actions.SET_OBJECT_TYPE, SET_OBJECT_TYPE),
    takeEvery(actions.SET_ENDPOINT, SET_ENDPOINT),
    takeEvery(actions.UPDATE_ENTITY_VALUE, UPDATE_ENTITY_VALUE),
    takeEvery(actions.SET_RELATION_ROWS, SET_RELATION_ROWS),
    takeEvery(actions.UPDATE_RELATION_ROW, UPDATE_RELATION_ROW),
    takeEvery(actions.TOGGLE_EDITABLE_RELATION_ROW, TOGGLE_EDITABLE_RELATION_ROW),
    takeEvery(actions.DELETE_RELATION_ROW, DELETE_RELATION_ROW),
    takeEvery(actions.LOAD_COMMENTS, LOAD_COMMENTS),
    takeEvery(actions.APPEND_COMMENT_REPLY, APPEND_COMMENT_REPLY),
    takeEvery(actions.RESET_COMMENT_REPLY, RESET_COMMENT_REPLY),
  ])
}
