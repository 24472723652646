import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { fetchParameterItems } from 'services/specifications/fetch'
import actions from './actions'

export function* LOAD_PARAMETER_ITEMS({ payload }) {
  const { type } = payload
  const items = yield call(fetchParameterItems, type)

  yield put({
    type: actions.SET_PARAMETER_ITEMS,
    payload: {
      type,
      items: items?.map(({ node }) => node),
    },
  })
}

export function* ADD_PARAMETER_ITEM({ payload: { data, type } }) {
  const prevObjectData = yield select(({ specifications }) => specifications.parameterItems[type])
  const newState = [...[data], ...prevObjectData]

  yield put({
    type: actions.SET_PARAMETER_ITEMS,
    payload: {
      type,
      items: newState,
    },
  })
}

export function* UPDATE_PARAMETER_ITEM({ payload: { data, type, isNew } }) {
  const prevObjectData = yield select(({ specifications }) => specifications.parameterItems[type])
  const prevDataIndex = prevObjectData.findIndex((item) =>
    isNew ? item.id === 'new' : item.id === data.id,
  )
  const newState = [...prevObjectData]

  newState[prevDataIndex] = { ...newState[prevDataIndex], ...data }

  yield put({
    type: actions.SET_PARAMETER_ITEMS,
    payload: {
      type,
      items: newState,
    },
  })
}

export function* REMOVE_PARAMETER_ITEM({ payload: { id, type } }) {
  const prevObjectData = yield select(({ specifications }) => specifications.parameterItems[type])
  const newState = prevObjectData.filter((item) => item.id !== id)

  yield put({
    type: actions.SET_PARAMETER_ITEMS,
    payload: {
      type,
      items: newState,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_PARAMETER_ITEMS, LOAD_PARAMETER_ITEMS),
    takeEvery(actions.ADD_PARAMETER_ITEM, ADD_PARAMETER_ITEM),
    takeEvery(actions.UPDATE_PARAMETER_ITEM, UPDATE_PARAMETER_ITEM),
    takeEvery(actions.REMOVE_PARAMETER_ITEM, REMOVE_PARAMETER_ITEM),
  ])
}
