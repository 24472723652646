import { mapKeys, toLower } from 'lodash'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { getFields } from 'services/fields'
import { getModules } from 'services/modules'
import actions from './actions'

export function* LOAD_FIELDS({ payload }) {
  const { slug } = payload

  // Look for an existing value
  const { fields: currentState } = yield select((state) => state.modules[slug] || {})

  if (!currentState) {
    const fields = yield call(getFields, slug)

    // Store the fields
    yield put({
      type: actions.SET_FIELDS,
      payload: {
        module: slug,
        fields,
      },
    })
  }
}

export function* LOAD_MODULES() {
  const modules = yield call(getModules)

  const renamedModules = mapKeys(modules, (value) => toLower(value.slug))

  yield put({
    type: actions.SET_MODULES,
    payload: renamedModules,
  })
}

export function* LOAD_ACCOUNT_MODULES() {
  const modules = yield call(getModules)

  const renamedModules = mapKeys(modules, (value) => toLower(value.slug))

  yield put({
    type: actions.SET_ACCOUNT_MODULES,
    payload: { accountModules: renamedModules },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FIELDS, LOAD_FIELDS),
    takeEvery(actions.LOAD_MODULES, LOAD_MODULES),
    takeEvery(actions.LOAD_ACCOUNT_MODULES, LOAD_ACCOUNT_MODULES),
  ])
}
