import actions from './actions'

const initialState = {
  loaded: false,
  accountModulesLoaded: false,
  accountModules: [],
}

export default function modulesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.SET_FIELDS:
      return {
        ...state,
        ...{
          [action.payload.module]: {
            ...state[action.payload.module],
            ...{ fields: action.payload.fields },
          },
        },
      }

    case actions.UPDATE_MODULE:
      return {
        ...state,
        ...{
          [action.payload.module]: {
            ...state[action.payload.module],
            ...action.payload.data,
          },
        },
      }

    case actions.SET_MODULES:
      return {
        ...state,
        ...action.payload,
        loaded: true,
      }

    case actions.SET_ACCOUNT_MODULES:
      return {
        ...state,
        ...action.payload,
        accountModulesLoaded: true,
      }

    default:
      return state
  }
}
