const actions = {
  SET_STATE: 'letters/SET_STATE',
  RESET_STATE: 'letters/RESET_STATE',
  SET_PARAMETER_ITEMS: 'letters/SET_PARAMETER_ITEMS',
  LOAD_PARAMETER_ITEMS: 'letters/LOAD_PARAMETER_ITEMS',
  ADD_PARAMETER_ITEM: 'letters/ADD_PARAMETER_ITEM',
  UPDATE_PARAMETER_ITEM: 'letters/UPDATE_PARAMETER_ITEM',
  REMOVE_PARAMETER_ITEM: 'letters/REMOVE_PARAMETER_ITEM',
}

export default actions
