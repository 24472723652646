import store from 'store'
import actions from './actions'

const STORED_SETTINGS = (storedSettings) => {
  const settings = {}

  Object.keys(storedSettings).forEach((key) => {
    const item = store.get(`app.settings.${key}`)

    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key]
  })

  return settings
}

const initialState = {
  ...STORED_SETTINGS({
    authProvider: 'jwt',
    logo: 'Mailabl',
    version: 'clean',
    theme: 'default',
    locale: 'en',
    isMobileView: false,
    isMobileMenuOpen: false,
    isMenuCollapsed: false,
    menuLayoutType: 'left',
    routerAnimation: 'slide-fadein-up',
    menuColor: 'white',
    authPagesColor: 'gray',
    isAuthTopbar: true,
    leftMenuWidth: 140,
    isMenuUnfixed: false,
    isCardShadow: true,
    isSquaredBorders: false,
    isBorderless: false,
    layoutMenu: 'classic',
    flyoutMenuType: 'flyout',
    flyoutMenuColor: 'blue',
    taskpanePinSeen: false,
    defaultStatus: null,
    defaultDeadline: null,
  }),
}

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.RESET_STATE:
      return initialState

    default:
      return state
  }
}
