// eslint-disable-next-line import/prefer-default-export
export const getMainQuery = () => `
  moduleFields(slug: $slug) {
    type
    name
    enum
    label
    defaultValue
    values
    settings
    columnVisibility
    sorter
    isMainField
    isSystemField
    isUnique
    isRequired
    isFilterable
    columnSortOrder
    filterSortOrder
  }
`
