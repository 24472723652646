import { gql } from '@apollo/client'
import apiClient from 'services/apollo'
import { FetchAllData, FetchData, FetchEntity } from 'services/apollo/helpers'
import { getContactsTaskpaneQuery } from 'services/queries/contacts'
import { getCorrespondenceQuery } from 'services/queries/letters'

export const getContactsByEmail = async (email) => {
  const data = await FetchAllData({
    endpoint: 'getContactsByEmail',
    fields: [getContactsTaskpaneQuery()],
    params: [
      {
        field: 'email',
        type: 'String!',
        value: email,
      },
    ],
  })

  return data
}

export const getTasks = async (contactId) => {
  const data = await FetchAllData({
    endpoint: 'tasks',
    fields: [
      `
        id
        title
        status {
          id
          name
          color
        }
        priority
      `,
    ],
    hasConditions: [
      {
        field: 'hasContacts',
        value: `{
          column: ID,
          operator: IN,
          value: [${contactId}],
        }`,
      },
    ],
  })

  return data
}

export const getProperties = async (contactId) => {
  const data = await FetchAllData({
    endpoint: 'properties',
    fields: [
      `
        id
        displayAddress
      `,
    ],
    hasConditions: [
      {
        field: 'hasContacts',
        value: `{
          column: ID,
          operator: IN,
          value: [${contactId}],
        }`,
      },
    ],
  })

  return data
}

export const getLetterByImmutableId = async (immutableId) => {
  const data = await FetchData({
    endpoint: 'letters',
    fields: ['id'],
    where: {
      column: 'EXTERNAL_ID',
      value: immutableId,
    },
    whereConditionsName: 'QueryLettersWhereWhereConditions',
  })

  return data
}

export const getCorrespondenceByExternalId = async (id) => {
  const data = await FetchData({
    endpoint: 'letters',
    fields: ['id parent { id }'],
    where: {
      AND: [
        {
          column: 'CORRESPONDENCE_ID',
          value: id,
        },
      ],
    },
    whereConditionsName: 'QueryLettersWhereWhereConditions',
    first: 1,
  })

  const node = data?.data?.[0]?.node

  if (node && node?.id) {
    const response = await FetchEntity({
      endpoint: 'correspondence',
      id: node?.parent?.id || node.id,
      fields: getCorrespondenceQuery(),
    })

    return response
  }

  return null
}

export const base64ToBlob = (base64, type = 'application/octet-stream') =>
  fetch(`data:${type};base64,${base64}`).then((response) => {
    if (response.ok) {
      return response.blob()
    }

    return response
  })

export const getFileUploadFormData = ({ file, id, filename, item }) => {
  const formData = new FormData()

  formData.append(
    'operations',
    JSON.stringify({
      query: `mutation UploadFile($input: UploadFileInput!) {
                uploadFile(input: $input) {
                  __typename
                  uuid
                }
              }`,
      operationName: 'UploadFile',
      variables: {
        input: {
          file: null,
          model: {
            type: 'LETTER',
            id,
          },
          data: JSON.stringify({
            isInline: item?.isInline,
            contentId: item?.contentId,
          }),
        },
      },
    }),
  )

  formData.append('map', JSON.stringify({ 0: ['variables.input.file'] }))
  formData.append('0', file, filename)

  return formData
}

export const logout = async () => {
  const mutation = gql`
    mutation logout {
      logout {
        status
        message
      }
    }
  `

  try {
    const response = await apiClient.mutate({
      mutation,
    })

    const data = await response.data.logout

    return data
  } catch (e) {
    return false
  }
}
