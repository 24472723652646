import { all, put, takeEvery } from 'redux-saga/effects'
import actions from './actions'

export function* CHANGE_VISIBILITY({ payload: { visible, objectType, onSuccess, parent } }) {
  yield put({
    type: 'create/SET_STATE',
    payload: {
      visible,
      objectType,
      onSuccess,
      parent,
    },
  })
}

export function* SET_IS_SAVING({ payload }) {
  yield put({
    type: 'create/SET_STATE',
    payload: {
      isSaving: payload ?? true,
      response: null,
    },
  })
}

export function* SET_SUCCESS_PATH({ payload }) {
  yield put({
    type: 'create/SET_STATE',
    payload: {
      successPath: payload,
    },
  })
}

export function* SET_RESPONSE({ payload: { response } }) {
  yield put({
    type: 'create/SET_STATE',
    payload: {
      response,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHANGE_VISIBILITY, CHANGE_VISIBILITY),
    takeEvery(actions.SET_IS_SAVING, SET_IS_SAVING),
    takeEvery(actions.SET_RESPONSE, SET_RESPONSE),
    takeEvery(actions.SET_SUCCESS_PATH, SET_SUCCESS_PATH),
  ])
}
