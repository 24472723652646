import actions from './actions'

const initialState = {
  message: null,
  immutableId: null,
  direction: null,
  externalLetter: null,
}

export default function letterReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.RESET_STATE:
      return initialState

    default:
      return state
  }
}
