const actions = {
  SET_STATE: 'dialog/SET_STATE',
  RESET_STATE: 'dialog/RESET_STATE',
  SET_LOADING: 'dialog/SET_LOADING',
  SET_SUCCESS_LOADING: 'dialog/SET_SUCCESS_LOADING',
  SET_SUCCESS_STATUS: 'dialog/SET_SUCCESS_STATUS',
  SET_SUCCESS_UPLOADING_MIME: 'dialog/SET_SUCCESS_UPLOADING_MIME',
  SET_SUCCESS_UPLOADING_ATTACHMENTS: 'dialog/SET_SUCCESS_UPLOADING_ATTACHMENTS',
  UPDATE_SUCCESS_LIST_DATA_SOURCE: 'dialog/UPDATE_SUCCESS_LIST_DATA_SOURCE',
  SET_SUMMARY_GENERATE_LOADING: 'dialog/SET_SUMMARY_GENERATE_LOADING',
}

export default actions
