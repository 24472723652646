// eslint-disable-next-line import/prefer-default-export
export function getMessageText({
  type = 'success',
  action = 'update',
  text = 'editableField',
  values,
  intl,
}) {
  return intl.formatMessage({ id: `messages.${text}.${action}.${type}` }, values)
}
