const actions = {
  SET_STATE: 'create/SET_STATE',
  RESET_STATE: 'create/RESET_STATE',
  CHANGE_VISIBILITY: 'create/CHANGE_VISIBILITY',
  SET_IS_SAVING: 'create/SET_IS_SAVING',
  SET_RESPONSE: 'create/SET_RESPONSE',
  SET_SUCCESS_PATH: 'create/SET_SUCCESS_PATH',
}

export default actions
