const actions = {
  SET_STATE: 'modules/SET_STATE',
  SET_FIELDS: 'modules/SET_FIELDS',
  SET_MODULES: 'modules/SET_MODULES',
  UPDATE_MODULE: 'modules/UPDATE_MODULE',
  LOAD_FIELDS: 'modules/LOAD_FIELDS',
  LOAD_MODULES: 'modules/LOAD_MODULES',
  LOAD_ACCOUNT_MODULES: 'modules/LOAD_ACCOUNT_MODULES',
  SET_ACCOUNT_MODULES: 'modules/SET_ACCOUNT_MODULES',
}

export default actions
