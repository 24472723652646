// eslint-disable-next-line import/prefer-default-export
export const getCommentsQuery = () => `
  __typename
  id
  body
  isAuthor
  author {
    displayName
  }
  parentId
  createdAt
`
