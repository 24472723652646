import { UnauthenticatedTemplate } from '@azure/msal-react'
import { Icon } from '@iconify/react'
import { Image, Skeleton, Typography } from 'antd'
import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { getAccount } from 'redux/user/selectors'
import { getUserProfileEmailAddress } from 'services/office'

const { Title } = Typography

export default () => {
  const intl = useIntl()
  const account = useSelector(getAccount)

  return (
    <UnauthenticatedTemplate>
      <div className="flex flex-col 100vh p-4 text-center text-lg">
        <Image
          src="/resources/icons/transfer-files.svg"
          width={130}
          rootClassName="mx-auto"
          preview={false}
        />

        <Title level={3} className="my-4">
          {intl.formatMessage({ id: 'login.graph.title' })}
        </Title>

        <div className="text-lg">{intl.formatMessage({ id: 'login.graph.slogan' })}</div>

        <div className="flex flex-col mt-5">
          <div>
            <div className="opacity-50">
              {intl.formatMessage({ id: 'login.graph.office.account.header' })}
            </div>

            <div className="text-truncate">{getUserProfileEmailAddress()}</div>
          </div>

          <div className="my-1">
            <Icon icon="bi:link-45deg" className="inline" inline />
          </div>

          <div>
            <div className="opacity-50">
              {intl.formatMessage({ id: 'login.graph.mailabl.account.header' })}
            </div>

            <div>{account?.name ?? <Skeleton />}</div>
          </div>
        </div>
      </div>
    </UnauthenticatedTemplate>
  )
}
