import { gql } from '@apollo/client'
import { toLower } from 'lodash'
import apiClient from 'services/apollo'

/**
 * Get the modules list enabled for the given account.
 */
export async function getModules() {
  // Prepare the query
  const query = gql`
    query me {
      me {
        account {
          modules {
            slug
            title
            icon
            permissible
            permissions {
              slug
              name
              type
              displayType
            }
          }
        }
      }
    }
  `

  // Query for the fields
  const response = await apiClient.query({
    query,
  })

  const modules = await response.data?.me?.account?.modules

  return modules
}

/**
 * Get the module's endpoint from the item's typename.
 */
export function getModuleEndpointFromTypename(record) {
  // eslint-disable-next-line no-underscore-dangle
  return toLower(record?.__typename)
}

/**
 * Get the module's name from the item's typename.
 */
export function getModuleNameFromTypename(record) {
  return `${getModuleEndpointFromTypename(record)}s`
}
