import actions from './actions'

const initialState = {
  modules: [],
}

export default function statusReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.RESET_STATE:
      return initialState

    case actions.SET_STATUSES:
      return {
        ...state,
        ...{
          [action.payload.objectType]: action.payload.statuses,
        },
      }

    case actions.SET_MODULES:
      return {
        ...state,
        ...{
          modules: action.payload,
        },
      }

    default:
      return state
  }
}
