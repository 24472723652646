import actions from './actions'

const initialState = {
  loading: true,
  successLoading: true,
  successStatus: undefined,
  successUploadingMime: false,
  successUploadingAttachments: false,
  successListDataSource: {
    overall: [
      {
        type: 'data',
        value: true,
      },
      {
        type: 'mime',
      },
    ],
  },
  summaryGenerateLoading: false,
}

export default function dialogReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.RESET_STATE:
      return initialState

    case actions.SET_LOADING:
      return {
        ...state,
        ...{
          loading: action.payload,
        },
      }

    case actions.SET_SUCCESS_LOADING:
      return {
        ...state,
        ...{
          successLoading: action.payload,
        },
      }

    case actions.SET_SUCCESS_STATUS:
      return {
        ...state,
        ...{
          successStatus: action.payload,
        },
      }

    case actions.SET_SUCCESS_UPLOADING_MIME:
      return {
        ...state,
        ...{
          successUploadingMime: action.payload,
        },
      }

    case actions.SET_SUCCESS_UPLOADING_ATTACHMENTS:
      return {
        ...state,
        ...{
          successUploadingAttachments: action.payload,
        },
      }

    case actions.UPDATE_SUCCESS_LIST_DATA_SOURCE:
      return {
        ...state,
        ...{
          successListDataSource: {
            ...state.successListDataSource,
            [action.payload.key]: action.payload.value,
          },
        },
      }

    case actions.SET_SUMMARY_GENERATE_LOADING:
      return {
        ...state,
        ...{
          summaryGenerateLoading: action.payload,
        },
      }

    default:
      return state
  }
}
