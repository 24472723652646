// eslint-disable-next-line import/prefer-default-export
export const getMainQuery = () => `
  id
  type
  causer {
    id
    displayName
  }
  data {
    subject {
      id
      slug
    }
    label
    text
    type
  }
  createdAt
  readAt
`
