import { gql } from '@apollo/client'
import apiClient from 'services/apollo'
import { RunMutation } from 'services/apollo/helpers'
import axiosClient from 'services/axios'
import { getMainQuery } from 'services/queries/users'

export async function currentAccount() {
  const query = gql`
    query me {
      me {
        id
        firstName
        lastName
        email
        isAccountOwner
        language
        abilities
        account {
          id
          name
        }
        modules {
          title
          slug
          icon
          hasSingleView
          inMenu
          permissible
          hasStatus
          hasComments
          sortOrder
          children {
            title
            slug
            icon
            hasSingleView
            inMenu
            permissible
            hasStatus
            hasComments
            sortOrder
          }
        }
        settingTaskpanePinSeen: globalSetting(name: "outlook.taskpanePinSeen") {
          value
        }
        settingDefaultStatus: globalSetting(name: "letters.defaultStatus") {
          value
        }
        settingDefaultDeadline: globalSetting(name: "letters.defaultDeadline") {
          value
        }
      }
      authenticated
      users(
        orderBy: [{column: DISPLAY_NAME, order: ASC}]
      ) {
        edges {
          node {
            ${getMainQuery()}
          }
        }
      }
    }
  `

  return apiClient
    .query({
      query,
    })
    .then((response) => {
      if (response) {
        return response.data
      }

      return false
    })
    .catch(() => false)
}

export async function login(username, password, message) {
  return axiosClient
    .post('/auth/login', {
      username,
      password,
    })
    .then((response) => {
      if (response) {
        return currentAccount()
      }

      return false
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message

      if (errorMessage) {
        message.error(errorMessage)
      }
    })
}

export async function logout() {
  RunMutation({
    mutationName: 'logout',
    enableParameters: false,
    queryFields: 'status message',
  })
}
