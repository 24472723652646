import { gql } from '@apollo/client'
import apiClient from 'services/apollo'
import { ucFirst } from 'services/helpers/text'
import { getCommentsQuery } from 'services/queries/comments'

/* eslint-disable import/prefer-default-export */
export async function fetchComments(endpoint, id) {
  const ucFirstEndpoint = ucFirst(endpoint)
  const fragmentName = `CommentsRecursive${ucFirstEndpoint}`
  const variables = {
    id,
  }

  const query = gql`
    fragment CommentFields on Comment {
      ${getCommentsQuery()}
    }

    fragment ${fragmentName} on ${ucFirstEndpoint} {
      comments {
        ...CommentFields
        children {
          ...CommentFields
        }
      }
    }

    query ${endpoint}($id: ID!) {
      ${endpoint}(id: $id) {
        ...${fragmentName}
      }
    }
  `

  try {
    const response = await apiClient.query({
      query,
      variables,
    })

    // Await for the response to resolve
    const data = await response.data[endpoint]?.comments

    return data
  } catch (e) {
    return false
  }
}
