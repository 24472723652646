import actions from './actions'

const initialState = {
  id: null,
  firstName: '',
  lastName: '',
  roles: '',
  email: '',
  avatar: '',
  authorized: false,
  authorizationChecked: false,
  isAccountOwner: false,
  loading: false,
  graphData: {},
  modules: {},
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.RESET_STATE:
      return initialState

    default:
      return state
  }
}
