const actions = {
  SET_STATE: 'submissions/SET_STATE',
  RESET_STATE: 'submissions/RESET_STATE',
  SET_PARAMETER_ITEMS: 'submissions/SET_PARAMETER_ITEMS',
  LOAD_PARAMETER_ITEMS: 'submissions/LOAD_PARAMETER_ITEMS',
  ADD_PARAMETER_ITEM: 'submissions/ADD_PARAMETER_ITEM',
  UPDATE_PARAMETER_ITEM: 'submissions/UPDATE_PARAMETER_ITEM',
  REMOVE_PARAMETER_ITEM: 'submissions/REMOVE_PARAMETER_ITEM',
}

export default actions
