const actions = {
  SET_STATE: 'list/SET_STATE',
  RESET_STATE: 'list/RESET_STATE',
  SET_SEARCH_TERM: 'list/SET_SEARCH_TERM',
  SET_APPLIED_FILTERS: 'list/SET_APPLIED_FILTERS',
  SET_ACTIVE_FILTERS_COUNT: 'list/SET_ACTIVE_FILTERS_COUNT',
  SET_PAGINATION: 'list/SET_PAGINATION',
  SET_WHERE_CONDITIONS: 'list/SET_WHERE_CONDITIONS',
  SET_SORTER: 'list/SET_SORTER',
  SET_FETCH_DATA: 'list/SET_FETCH_DATA',
  DELETE_ENTITY: 'list/DELETE_ENTITY',
  SET_DELETING: 'list/SET_DELETING',
  DELETE_SUCCESS: 'list/DELETE_SUCCESS',
  RESET_DELETING: 'list/RESET_DELETING',
  UPDATE_ROW: 'list/UPDATE_ROW',
  LOAD_LIST_DATA: 'list/LOAD_LIST_DATA',
  SET_LIST_DATA_LOADED: 'list/SET_LIST_DATA_LOADED',
  SET_SAVED_VIEW: 'list/SET_SAVED_VIEW',
  SET_FILTERABLE_FIELDS: 'list/SET_FILTERABLE_FIELDS',
  SET_COLUMNS: 'list/SET_COLUMNS',
  SET_OBJECT_TYPE: 'list/SET_OBJECT_TYPE',
  CLEAR: 'list/CLEAR',
}

export default actions
