import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser'
import msalInstance from './instance'

/**
 * This class implements the IAuthenticationProvider interface, which allows a custom auth provider to be
 * used with the Graph client. See: https://github.com/microsoftgraph/msgraph-sdk-javascript/blob/dev/src/IAuthenticationProvider.ts
 */
class MsalAuthenticationProvider {
  account // user account object to be used when attempting silent token acquisition

  scopes // array of scopes required for this resource endpoint

  interactionType

  constructor(providerOptions) {
    this.account = providerOptions.account
    this.scopes = providerOptions.scopes
    this.interactionType = providerOptions.interactionType
  }

  /**
   * This method will get called before every request to the ms graph server
   * This should return a Promise that resolves to an accessToken (in case of success) or rejects with error (in case of failure)
   * Basically this method will contain the implementation for getting and refreshing accessTokens
   */
  getAccessToken() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      let response

      try {
        response = await msalInstance.acquireTokenSilent({
          account: this.account,
          scopes: this.scopes,
        })

        if (response.accessToken) {
          resolve(response.accessToken)
        } else {
          reject(Error('Failed to acquire an access token'))
        }
      } catch (error) {
        // in case if silent token acquisition fails, fallback to an interactive method
        if (error instanceof InteractionRequiredAuthError) {
          if (this.interactionType === InteractionType.Popup) {
            response = await msalInstance.acquireTokenPopup({
              scopes: this.scopes,
            })
          }

          if (this.interactionType === InteractionType.Redirect) {
            response = await msalInstance.acquireTokenRedirect({
              scopes: this.scopes,
            })
          }

          if (response.accessToken) {
            resolve(response.accessToken)
          } else {
            reject(Error('Failed to acquire an access token'))
          }
        }
      }
    })
  }
}

export default MsalAuthenticationProvider
