import { theme } from 'antd'

const overall = {
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Mukta', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  fontWeightStrong: 600,
  colorPrimary: '#d24848',
}

export const light = {
  algorithm: theme.defaultAlgorithm,
  token: {
    ...overall,
    ...{
      colorBgLayout: '#ebf2f1',
      colorLink: '#404264',
      colorLinkHover: '#7da3bd',
      colorPrimaryHover: '#d9d9d9',
      colorPrimaryActive: '#d9d9d9',
    },
  },
  components: {
    Button: {
      colorPrimary: '#6fa29b',
      colorPrimaryHover: '#7daba5',
      colorPrimaryActive: '#7daba5',
    },
    Radio: {
      colorPrimary: '#7da3bd',
      colorPrimaryHover: '#8aacc4',
      colorPrimaryActive: '#8aacc4',
    },
    Select: {
      colorPrimary: '#4b7cf3',
      controlItemBgActive: '#f0f7ff',
    },
    Switch: {
      colorPrimary: '#feceab',
      colorPrimaryHover: '#fed3b3',
      colorPrimaryActive: '#fed3b3',
    },
    Input: {
      colorPrimary: '#4b7cf3',
    },
    InputNumber: {
      colorPrimary: '#4b7cf3',
    },
    Checkbox: {
      colorPrimary: '#4b7cf3',
    },
    Drawer: {
      colorBgElevated: '#ebf2f1',
    },
    Table: {
      colorIcon: 'rgba(0, 0, 0, 0.45)',
    },
    DatePicker: {
      colorPrimary: '#7da3bd',
      controlItemBgActive: '#e5edf2',
    },
  },
}

export const dark = {
  algorithm: theme.darkAlgorithm,
  token: {
    ...overall,
    ...{
      colorBgLayout: '#373747',
      // colorPrimary: '#6fa29b',
    },
  },
}
