export const getColumns = () => [
  {
    type: 'child',
    field: 'displayAddress',
    width: '60%',
  },
  {
    title: 'easements.properties.area',
    type: 'pivot',
    field: 'area',
    subQueryFields: ['size', 'unit'],
    align: 'right',
    width: '20%',
  },
  {
    title: 'easements.properties.payable',
    type: 'pivot',
    field: 'isPayable',
    align: 'right',
    width: '20%',
  },
]

export const getAdditionalQueryFields = () => [
  {
    type: 'child',
    fields: `cadastralUnitNumber`,
  },
  {
    type: 'pivot',
    fields: `
      pricePerAreaUnit {
        amount
        currencyCode
      }
      totalPrice {
        amount
        currencyCode
      }
      nextPaymentDate
    `,
  },
]

export const getOrderBy = () => [
  {
    column: 'DISPLAY_ADDRESS',
    order: 'ASC',
  },
]
