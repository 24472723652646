import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { fetchStatusModules, fetchStatuses } from 'services/statuses/fetch'
import actions from './actions'

export function* LOAD_STATUSES({ payload }) {
  const { objectType } = payload
  const statuses = yield call(fetchStatuses, objectType)

  yield put({
    type: actions.SET_STATUSES,
    payload: {
      objectType,
      statuses: statuses?.map(({ node }) => node),
    },
  })
}

export function* LOAD_MODULES() {
  const modules = yield call(fetchStatusModules)

  yield put({
    type: actions.SET_MODULES,
    payload: modules,
  })
}

export function* ADD_STATUS({ payload: { data, objectType } }) {
  const prevObjectData = yield select(({ statuses }) => statuses[objectType])
  const newState = [...prevObjectData, ...[data]]

  yield put({
    type: actions.SET_STATE,
    payload: {
      [objectType]: newState,
    },
  })
}

export function* UPDATE_STATUS({ payload: { data, objectType, isNew } }) {
  const prevObjectData = yield select(({ statuses }) => statuses[objectType])
  const prevDataIndex = prevObjectData.findIndex((item) =>
    isNew ? item.id === 'new' : item.id === data.id,
  )
  const newState = [...prevObjectData]

  newState[prevDataIndex] = { ...newState[prevDataIndex], ...data }

  yield put({
    type: actions.SET_STATE,
    payload: {
      [objectType]: newState,
    },
  })
}

export function* REMOVE_STATUS({ payload: { id, objectType } }) {
  const prevObjectData = yield select(({ statuses }) => statuses[objectType])
  const newState = prevObjectData.filter((item) => item.id !== id)

  yield put({
    type: actions.SET_STATE,
    payload: {
      [objectType]: newState,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_STATUSES, LOAD_STATUSES),
    takeEvery(actions.LOAD_MODULES, LOAD_MODULES),
    takeEvery(actions.ADD_STATUS, ADD_STATUS),
    takeEvery(actions.UPDATE_STATUS, UPDATE_STATUS),
    takeEvery(actions.REMOVE_STATUS, REMOVE_STATUS),
  ])
}
