import { FetchAllData } from 'services/apollo/helpers'

// eslint-disable-next-line import/prefer-default-export
export async function fetchTypes() {
  const payload = {
    endpoint: 'taskTypes',
    fields: ['id', 'name'],
  }

  const response = await FetchAllData(payload)

  return response
}
