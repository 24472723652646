import { all } from 'redux-saga/effects'
import contracts from './contracts/sagas'
import coordinations from './coordinations/sagas'
import create from './create/sagas'
import easements from './easements/sagas'
import editors from './editors/sagas'
import entities from './entities/sagas'
import letter from './letter/sagas'
import letters from './letters/sagas'
import list from './list/sagas'
import modules from './modules/sagas'
import notifications from './notifications/sagas'
import ordinances from './ordinances/sagas'
import settings from './settings/sagas'
import specifications from './specifications/sagas'
import statuses from './statuses/sagas'
import submissions from './submissions/sagas'
import tags from './tags/sagas'
import taskTypes from './taskTypes/sagas'
import user from './user/sagas'
import users from './users/sagas'
import view from './view/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    modules(),
    settings(),
    users(),
    create(),
    view(),
    list(),
    entities(),
    statuses(),
    tags(),
    taskTypes(),
    editors(),
    letters(),
    coordinations(),
    specifications(),
    ordinances(),
    contracts(),
    submissions(),
    easements(),
    notifications(),
    letter(),
  ])
}
