import { FetchAllData } from 'services/apollo/helpers'
import { ucFirst } from 'services/helpers/text'

// eslint-disable-next-line import/prefer-default-export
export async function fetchParameterItems(type) {
  const payload = {
    endpoint: `easement${ucFirst(type)}`,
    fields: ['id', 'name'],
  }

  const response = await FetchAllData(payload)

  return response
}
