export const setCookie = (cookieKey, cookieValue = '', expirationDays) => {
  let expires = ''

  if (expirationDays) {
    const date = new Date()

    date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000)

    expires = `; expires=${date.toUTCString()}`
  }

  document.cookie = `${cookieKey}=${cookieValue}${expires}; secure; SameSite=Strict; path=/`
}

export const getCookie = (cookieKey) => {
  const name = `${cookieKey}=`
  const cookies = decodeURIComponent(document.cookie).split('; ')

  let cookieValue

  cookies.forEach((val) => {
    if (val.indexOf(name) === 0) {
      cookieValue = val.substring(name.length)
    }
  })

  return cookieValue
}
