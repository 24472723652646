/* global Office */
import { StyleProvider } from '@ant-design/cssinjs'
import { ApolloProvider } from '@apollo/client'
import { EventType } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import * as Sentry from '@sentry/react'
import ErrorFallback from 'components/Errors/500'
import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import ReactGA from 'react-ga4'
import { Provider } from 'react-redux'
import apiClient from 'services/apollo'
import msalInstance from 'services/graph/instance'
import Localization from './localization'
import CustomNavigationClient from './navigationClient'
import reportWebVitals from './reportWebVitals'
import Router from './router'
import SentryConfig from './sentry'
import * as serviceWorker from './serviceWorker'
import { history, store } from './store'
import StylesLoader from './stylesLoader'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    SentryConfig,
  })
}

const root = createRoot(document.getElementById('root'))

const sendWebVitals = ({ id, name, value }) => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.event({
      category: 'Web Vitals',
      action: 'name',
      // The `id` value will be unique to the current page load. When sending
      // multiple values from the same page (e.g. for CLS), Google Analytics can
      // compute a total by grouping on this ID (note: requires `eventLabel` to
      // be a dimension in your report).
      label: id,
      // Google Analytics metrics must be integers, so the value is rounded.
      // For CLS the value is first multiplied by 1000 for greater precision
      // (note: increase the multiplier for greater precision if needed)
      value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
      // Use a non-interaction event to avoid affecting bounce rate.
      nonInteraction: true, // optional, true/false
    })
  }
}

const App = () => {
  const navigationClient = new CustomNavigationClient(history)

  msalInstance.setNavigationClient(navigationClient)

  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
  }

  msalInstance.enableAccountStorageEvents()

  useEffect(() => {
    const callbackId = msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const { account } = event.payload

        msalInstance.setActiveAccount(account)
      }
    })

    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId)
      }
    }
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <Provider store={store}>
        <ApolloProvider client={apiClient}>
          <MsalProvider instance={msalInstance}>
            <StylesLoader>
              <Localization>
                <StyleProvider hashPriority="high">
                  <Router history={history} />
                </StyleProvider>
              </Localization>
            </StylesLoader>
          </MsalProvider>
        </ApolloProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  )
}

Office.onReady(() => {
  root.render(<App />)
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendWebVitals)
