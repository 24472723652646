import localeAntd from 'antd/es/locale/en_US'

const messages = {
  /**
   * General.
   */
  loading: 'Loading',
  email: 'Email',
  password: 'Password',
  undefined: 'Undefined',
  chooseHere: 'choose here',
  cancel: 'Cancel',
  'topBar.typeToSearch': 'Search...',

  /**
   * Authentication.
   */
  'login.title': 'Welcome to Mailabl',
  'login.slogan':
    'Unify your customer management with the all-in-one hub for relationships, properties, correspondence, and more',
  'login.signInHeader': 'Sign in to your account',
  'login.btn': 'Sign in',
  'login.rules.email': 'Please input your e-mail address',
  'login.rules.password': 'Please input your password',
  'login.graph.title': 'One last step!',
  'login.graph.slogan':
    'For Mailabl to work with your inbox, we need to link your accounts together.\n\nPlease link your Office 365 inbox account to your Mailabl account to continue.',
  'login.graph.office.account.header': 'Office 365 Inbox',
  'login.graph.mailabl.account.header': 'Mailabl Account',
  'login.graph.inProgress': 'Authentication in progress...',
  'login.graph.error':
    'An error occured during the authentication process with Office 365.\n\nPlease close the add-in and open it again. Sometimes that might help.',
  signOut: 'Sign out',

  /**
   * Notifications.
   */
  'notifications.error.graphQL.query.title': 'Oops, something went wrong!',
  'notifications.error.graphQL.query.message':
    "Your query failed for some reason and we're looking into it.",

  /**
   * Taskpane.
   */
  'taskpane.registerMessage': 'Register message',
  'taskpane.contact.header': 'Contact',
  'taskpane.properties.header': 'Properties',
  'taskpane.tasks.header': 'Tasks',
  'taskpane.alreadyRegistered': 'This message has already been registered.',
  'taskpane.openExternalLetter': 'Open it in Mailabl',
  'taskpane.options.header':
    '{email} has multiple matching contacts. Assign a suitable one for this particular message.',
  'taskpane.options.btn.assign': 'assign',
  'taskpane.contact.search.title': 'Select a suitable contact for this particular message:',
  'taskpane.contact.noData.noMatches': 'No match found for',
  'taskpane.contact.noData.youCan': 'You can either',
  'taskpane.contact.noData.manually': 'Search manually',
  'taskpane.contact.noData.or': 'or',
  'taskpane.contact.noData.createContact': 'Create a new contact',
  'taskpane.contact.create.firstName': 'First name',
  'taskpane.contact.create.lastName': 'Last name',
  'taskpane.contact.create.companyName': 'Company name',
  'taskpane.contact.create.email': 'Email address',
  'taskpane.contact.create.type.person': 'Person',
  'taskpane.contact.create.type.company': 'Company',
  'taskpane.contact.create.submit': 'Submit',
  'taskpane.pin.message': 'Be sure to pin this taskpane',
  'taskpane.pin.description': "That way you don't have to open it again for each message.",
  'taskpane.pin.close': 'Got it',

  /**
   * Empty.
   */
  empty: 'empty',
  'empty.nothingHereYet': 'Nothing here yet.',

  /**
   * Tasks.
   */
  'tasks.priority.URGENT': 'Urgent',
  'tasks.priority.HIGH': 'High',
  'tasks.priority.MEDIUM': 'Medium',
  'tasks.priority.LOW': 'Low',

  /**
   * Dialog.
   */
  'dialog.field.correspondenceId': 'Correspondence',
  'dialog.field.status': 'Status',
  'dialog.field.summary': 'Summary',
  'dialog.field.summary.tooltip':
    "If the letter's subject isn't informative enough you can write a better summary about the letter's contents here",
  'dialog.field.summary.ai': 'Generate with AI',
  'dialog.field.type': 'Type',
  'dialog.field.responsible': 'Responsible',
  'dialog.field.members': 'Members',
  'dialog.field.contacts': 'Contacts',
  'dialog.field.projects': 'Projects',
  'dialog.field.properties': 'Properties',
  'dialog.field.tasks': 'Tasks',
  'dialog.field.dueAt': 'Due date',
  'dialog.submit': 'Submit',
  'dialog.success.title.processing': 'Processing...',
  'dialog.success.title.success': 'Letter submitted!',
  'dialog.success.title.error': 'Letter submitted, but with errors...',
  'dialog.success.openExternalBtn': 'Open it in Mailabl',
  'dialog.success.list.data.success': 'Data sent',
  'dialog.success.list.mime': 'Uploading email source file',
  'dialog.success.list.mime.success': 'Email source file uploaded',
  'dialog.success.list.mime.error': 'Email source file upload failed',
  'dialog.success.list.attachments': 'Uploading attachments',
  'dialog.success.list.attachments.success': 'Attachments uploaded',
  'dialog.success.list.attachments.error': 'Attachments upload failed',
  'dialog.message.wroteOn': 'wrote on',
  'dialog.summaryGeneratorFailed':
    'Unable to generate summary with AI due to a data retrieval issue. Please try again or enter the summary manually.',
}

export default {
  locale: 'en',
  localeAntd,
  messages,
}
