import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import * as Sentry from '@sentry/react'
import { notification as notificationComponent } from 'antd'
import english from 'locales/en'
import estonian from 'locales/et'
import { createIntl, createIntlCache } from 'react-intl'
import { getCookie } from 'services/helpers/cookies'

const locales = {
  en: english,
  et: estonian,
}

const locale = getCookie('locale') || 'en'
const cache = createIntlCache()

const intl = createIntl(
  {
    locale,
    messages: locales[locale].messages || {},
  },
  cache,
)

// Define the general HTTP link
const httpLink = createHttpLink({
  uri: `${window.REACT_APP_BASE_HOST}/graphql`,
  credentials: 'include',
})

const displayErrorMessage = (message) => {
  const exclude = ['Unauthenticated.']

  if (!exclude.includes(message)) {
    notificationComponent.error({
      message: intl.formatMessage({ id: 'notifications.error.graphQL.query.title' }),
      description: intl.formatMessage({ id: 'notifications.error.graphQL.query.message' }),
    })
  }
}

// eslint-disable-next-line no-unused-vars
const handleGraphQLErrorResponse = ({ message, locations, path, extensions }) => {
  /* console.log(
    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    extensions,
  ) */

  const { category } = extensions || {}

  let displayableErrorMessage = message
  let reportToSentry = true

  if (category?.length) {
    const categoryErrors = extensions?.[category] || {}

    // eslint-disable-next-line no-unused-vars
    Object.entries(categoryErrors).forEach(({ 0: field, 1: fieldErrors }) => {
      if (fieldErrors?.length) {
        // eslint-disable-next-line prefer-destructuring
        displayableErrorMessage = fieldErrors[0]
      }
    })

    if (['validation', 'authorization'].includes(category)) {
      reportToSentry = false
    }
  }

  // Ignore "No such XXX found" && "Unauthenticated" errors
  if (reportToSentry && /such|found|unauthenticated/gi.test(message)) {
    reportToSentry = false
  }

  if (reportToSentry) {
    Sentry.captureMessage(message, 'error')
  }

  displayErrorMessage(displayableErrorMessage)
}

// Define the general error handling link
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((errorResponse) => {
      handleGraphQLErrorResponse(errorResponse)
    })
  }

  if (networkError) {
    // console.log(`[Network error]: ${networkError}`)
    const responseCode = networkError.response?.status

    // Disable error notices for Unauthenticated response
    if (responseCode !== 401) {
      Sentry.captureMessage(networkError, 'error')

      displayErrorMessage()
    }
  }
})

// Define the retry link
const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 2000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => !!error && error.statusCode !== 401,
  },
})

const link = from([errorLink, retryLink, httpLink])

const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
  link,
})

export default client
