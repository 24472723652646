import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { fetchTypes } from 'services/tasks/types/fetch'
import actions from './actions'

export function* LOAD_TYPES() {
  const types = yield call(fetchTypes)

  yield put({
    type: actions.SET_TYPES,
    payload: types?.map(({ node }) => node),
  })
}

export function* ADD_TYPE({ payload }) {
  const prevObjectData = yield select(({ taskTypes }) => taskTypes.types)
  const newState = [...[payload], ...prevObjectData]

  yield put({
    type: actions.SET_TYPES,
    payload: newState,
  })
}

export function* UPDATE_TYPE({ payload: { data, isNew } }) {
  const prevObjectData = yield select(({ taskTypes }) => taskTypes.types)
  const prevDataIndex = prevObjectData.findIndex((item) =>
    isNew ? item.id === 'new' : item.id === data.id,
  )
  const newState = [...prevObjectData]

  newState[prevDataIndex] = { ...newState[prevDataIndex], ...data }

  yield put({
    type: actions.SET_TYPES,
    payload: newState,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_TYPES, LOAD_TYPES),
    takeEvery(actions.ADD_TYPE, ADD_TYPE),
    takeEvery(actions.UPDATE_TYPE, UPDATE_TYPE),
  ])
}
