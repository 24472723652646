import React from 'react'
import { injectIntl } from 'react-intl'
import { Empty as BaseEmpty } from 'antd'

const Empty = ({ text, translate, intl }) => {
  // If no text or translation variable given use the default
  if (!text) {
    text = 'empty.nothingHereYet'
    translate = true
  }

  if (translate === true) {
    text = intl.formatMessage({ id: text })
  }

  return <BaseEmpty image={BaseEmpty.PRESENTED_IMAGE_SIMPLE} description={text} />
}

export default injectIntl(Empty)
