import { gql } from '@apollo/client'
import apiClient from 'services/apollo'
import { isValidJson } from 'services/helpers/array'
import { ucFirst } from 'services/helpers/text'
import { getMainQuery } from 'services/queries/modules/fields'

/**
 * Get the field by it's name.
 */
export function getFieldByName(fields, name) {
  return fields?.filter((field) => field.name === name)[0]
}

/**
 * Get the field's settings, JSON parsed.
 */
export function getFieldSettings(field) {
  if (field?.settings) {
    return isValidJson(field.settings) ? JSON.parse(field?.settings) : field.settings
  }

  return {}
}

/**
 * Get the modules' fields.
 */
export async function getFields(slug) {
  // Prepare the query
  const query = gql`
    query moduleFields($slug: String!) {
      ${getMainQuery()}
    }
  `

  // Query for the fields
  const response = await apiClient.query({
    query,
    variables: {
      slug,
    },
  })

  const fields = await response.data.moduleFields

  return fields
}

/**
 * Get the relation field name.
 */
export function getRelationFieldName(currentName, settings) {
  // Generally we're hoping to see this information in the fields' settings
  let name = settings?.relation?.name

  // As a fallback parse it from the current name, which mostly should end with "Id"
  if (!name) {
    const idIndex = currentName.indexOf('Id')

    if (idIndex !== -1) {
      name = currentName.substring(0, idIndex)
    } else {
      name = currentName
    }
  }

  return name
}

/**
 * Get the relation fields for the query.
 */
export function getRelationFieldsForQuery(currentName, settings) {
  const fieldName = getRelationFieldName(currentName)

  settings = getFieldSettings({ settings })

  // Fields we'll use in most cases
  let subFields = ['id', 'displayName']

  // Pivot fields
  let pivotFields = []

  // If there are fields specifically set use them instead
  if (settings?.relation?.fields) {
    subFields = settings.relation.fields
  }

  // If there are pivot fields specified
  if (settings?.relation?.pivotFields) {
    pivotFields = settings.relation.pivotFields
  }

  if (fieldName === 'members' && !pivotFields.length) {
    pivotFields = ['isResponsible']
  }

  // If the field name is plural we can expect to get multiple items.
  // This means we have to wrap the sub fields in a "data" wrapper
  if (fieldName.slice(-1) === 's') {
    subFields = `edges {
      node {
        ${subFields.join('\n')}
      }
      ${pivotFields.join('\n')}
    }`
  }

  return {
    [fieldName]: subFields,
  }
}

/**
 * Get the status fields for the query.
 */
export function getStatusFieldsForQuery() {
  return {
    status: ['id', 'name', 'color', 'type'],
  }
}

/**
 * Get the field's label.
 */
export function getFieldLabel(field) {
  return field?.label
}

/**
 * Get the field's value picker mode.
 */
export function getValuePickerMode(field, isFilter = false) {
  const settings = getFieldSettings(field)
  const mode = settings?.mode

  if (isFilter) {
    return settings?.filter?.mode || mode
  }

  if (field.name === 'responsible') {
    return undefined
  }

  return mode
}

/**
 * Get the helpers path of the conditional select picker.
 * When the object type and field name differ we'll rather truncate to a single-level folder
 */
export function getConditionalSelectHelpersPath(module, field) {
  if (module !== field.name) {
    return `${ucFirst(module)}/${ucFirst(field.name)}`
  }

  return ucFirst(module)
}

/**
 * Get the query helpers path of the conditional select picker.
 */
export function getConditionalSelectQueryHelpersPath(objectType) {
  const queryHelpers = {
    contacts: 'contacts',
    people: 'contacts',
    companies: 'contacts',
    properties: 'properties',
    easements: 'easements',
    users: 'users',
    roles: 'roles',
    tags: 'tags',
    projects: 'projects',
    tasks: 'tasks',
    clients: 'clients',
    members: 'users',
    responsibles: 'users',
    followers: 'users',
    creators: 'users',
    status: 'statuses',
    statuses: 'statuses',
    letters: 'letters',
    letterTypes: 'letterTypes',
    letterSources: 'letterSources',
    correspondences: 'correspondences',
    taskTypes: 'taskTypes',
    coordinations: 'coordinations',
    coordinationTypes: 'coordinationTypes',
    coordinationStages: 'coordinationStages',
    specifications: 'specifications',
    specificationTypes: 'specificationTypes',
    ordinances: 'ordinances',
    ordinanceTypes: 'ordinanceTypes',
    contracts: 'contracts',
    contractTypes: 'contractTypes',
    submissions: 'submissions',
    submissionTypes: 'submissionTypes',
    easementTypes: 'easementTypes',
  }

  return queryHelpers[objectType]
}

/**
 * Get the internal object type of the conditional select picker.
 */
export function getConditionalSelectInternalObjectType(
  dataFetchEndpoint,
  objectType,
  isFilter,
  field,
) {
  let fieldName = field?.name.slice(-1) === 's' ? field.name : `${field?.name}s`

  if (isFilter && field?.type && ['RELATION', 'STATUS'].includes(field.type)) {
    if (objectType === 'tasks' && fieldName === 'types') {
      fieldName = 'taskTypes'
    }

    if (objectType === 'letters' && fieldName === 'types') {
      fieldName = 'letterTypes'
    }

    if (objectType === 'letters' && fieldName === 'sources') {
      fieldName = 'letterSources'
    }

    if (objectType === 'coordinations' && fieldName === 'types') {
      fieldName = 'coordinationTypes'
    }

    if (objectType === 'coordinations' && fieldName === 'stages') {
      fieldName = 'coordinationStages'
    }

    if (objectType === 'specifications' && fieldName === 'types') {
      fieldName = 'specificationTypes'
    }

    if (objectType === 'ordinances' && fieldName === 'types') {
      fieldName = 'ordinanceTypes'
    }

    if (objectType === 'contracts' && fieldName === 'types') {
      fieldName = 'contractTypes'
    }

    if (objectType === 'submissions' && fieldName === 'types') {
      fieldName = 'submissionTypes'
    }

    if (objectType === 'easements' && fieldName === 'types') {
      fieldName = 'easementTypes'
    }

    if (objectType === 'projects' && fieldName === 'clients') {
      fieldName = 'clients'
    }

    return getConditionalSelectQueryHelpersPath(fieldName)
  }

  if (objectType === 'tasks' && fieldName === 'types') {
    return 'taskTypes'
  }

  if (objectType === 'letters' && fieldName === 'types') {
    return 'letterTypes'
  }

  if (objectType === 'letters' && fieldName === 'sources') {
    return 'letterSources'
  }

  if (objectType === 'coordinations' && fieldName === 'types') {
    return 'coordinationTypes'
  }

  if (objectType === 'coordinations' && fieldName === 'stages') {
    return 'coordinationStages'
  }

  if (objectType === 'specifications' && fieldName === 'types') {
    return 'specificationTypes'
  }

  if (objectType === 'ordinances' && fieldName === 'types') {
    return 'ordinanceTypes'
  }

  if (objectType === 'contracts' && fieldName === 'types') {
    return 'contractTypes'
  }

  if (objectType === 'submissions' && fieldName === 'types') {
    return 'submissionTypes'
  }

  if (objectType === 'easements' && fieldName === 'types') {
    return 'easementTypes'
  }

  if (objectType === 'projects' && fieldName === 'clients') {
    return 'clients'
  }

  return dataFetchEndpoint ?? objectType
}
