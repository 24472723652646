export const getColumns = () => [
  {
    type: 'child',
    field: 'number',
    width: '11%',
  },
  {
    title: 'subject',
    type: 'child',
    field: 'subject',
    width: '34%',
  },
  {
    type: 'child',
    field: 'sentAt',
    width: '15%',
  },
  {
    type: 'child',
    field: 'members',
    subQueryFields: ['edges { node { id displayName } isResponsible }'],
    width: '20%',
  },
  {
    type: 'child',
    field: 'status',
    subQueryFields: ['id', 'name', 'color', 'type'],
    width: '15%',
  },
]

export const getAdditionalQueryFields = () => [
  {
    type: 'child',
    fields: `summary direction`,
  },
]

export const getOrderBy = () => [
  {
    column: 'SENT_AT',
    order: 'DESC',
  },
]
