import { Layout } from 'antd'
import React from 'react'
import style from './style.module.scss'

const AuthLayout = ({ children }) => (
  <Layout>
    <Layout.Content>
      <div className={style.container}>
        <div className="mb-5">{children}</div>

        <div className="mt-auto pb-5 pt-5">
          <ul className={`${style.footerNav} list-unstyled flex mb-0 flex-wrap justify-center`}>
            <li>
              <a href="https://mailabl.com/terms-of-use/">Terms of Use</a>
            </li>
            <li>
              <a href="https://mailabl.com/privacy-policy/">Privacy policy</a>
            </li>
          </ul>

          <div className="text-center">Copyright © {new Date().getFullYear()} Mailabl</div>
        </div>
      </div>
    </Layout.Content>
  </Layout>
)

export default AuthLayout
