import { getAddressQuery } from '../address'

export const getMainQuery = () => `
  id
  type
  name
  lastName
  displayName
  regNumber
  vatNumber
  referenceNumber
  notes
  isClient
  isMember
  createdAt
  updatedAt
`

export const getPhonesQuery = () => `
  phones(
    first: 50
    orderBy: [
      {
        column: IS_DEFAULT,
        order: DESC
      }
    ]
  ) {
    edges {
      node {
        id
        value
        country
        code
        isDefault
      }
    }
  }
`

export const getDefaultPhoneQuery = () => `
  phones(
    first: 1
    where: {
      column: IS_DEFAULT
      operator: EQ
      value: true
    }
  ) {
    edges {
      node {
        code
        value
      }
    }
  }
`

export const getEmailsQuery = () => `
  emails(
    first: 50
    orderBy: [
      {
        column: IS_DEFAULT,
        order: DESC
      }
    ]
  ) {
    edges {
      node {
        id
        value
        isDefault
      }
    }
  }
`

export const getWebsitesQuery = () => `
  websites(
    first: 50
    orderBy: [
      {
        column: IS_DEFAULT,
        order: DESC
      }
    ]
  ) {
    edges {
      node {
        id
        value
        isDefault
      }
    }
  }
`

export const getDefaultEmailQuery = () => `
  emails(
    first: 1
    where: {
      column: IS_DEFAULT
      operator: EQ
      value: true
    }
  ) {
    edges {
      node {
        value
      }
    }
  }
`

export const getDefaultWebsiteQuery = () => `
  websites(
    first: 1
    where: {
      column: IS_DEFAULT
      operator: EQ
      value: true
    }
  ) {
    edges {
      node {
        value
      }
    }
  }
`

export const getContactsTaskpaneQuery = () => `
  id
  type
  displayName
  ${getPhonesQuery()}
  ${getEmailsQuery()}
`

export const getContactMeansOfContactQuery = (type) => {
  if (type === 'phones') {
    return getPhonesQuery()
  }

  if (type === 'emails') {
    return getEmailsQuery()
  }

  return getWebsitesQuery()
}

export const getContactMeansOfContactOnSaveQuery = () =>
  `${getPhonesQuery()}${getEmailsQuery()}${getWebsitesQuery()}`

export const getCompaniesInterrelationsQuery = () => `
  companies(
    first: 50
    orderBy: [
      {
        column: DISPLAY_NAME,
        order: ASC
      }
    ]
  ) {
    pageInfo {
      total
    }
    edges {
      node {
        id
        displayName
        ${getAddressQuery()}
        ${getPhonesQuery()}
        ${getEmailsQuery()}
        ${getWebsitesQuery()}
      }
      isDefault
    }
  }
`

export const getPeopleInterrelationsQuery = () => `
  people(
    first: 50
    orderBy: [
      {
        column: DISPLAY_NAME,
        order: ASC
      }
    ]
  ) {
    pageInfo {
      total
    }
    edges {
      node {
        id
        firstName
        lastName
        displayName
        ${getAddressQuery()}
        ${getPhonesQuery()}
        ${getEmailsQuery()}
        ${getWebsitesQuery()}
      }
      isDefault
    }
  }
`

export const getPropertiesQuery = () => `
  properties(
    first: 50
    orderBy: [
      {
        column: TYPE,
        order: DESC
      },
      {
        column: DISPLAY_ADDRESS,
        order: ASC
      }
    ]
  ) {
    pageInfo {
      total
    }
    edges {
      node {
        id
        displayAddress
        cadastralUnitNumber
      }
      type
    }
  }
`

export const getProjectsQuery = () => `
  projects(
    first: 50
    orderBy: [
      {
        column: NAME,
        order: ASC
      }
    ]
  ) {
    edges {
      node {
        id
        name
        status {
          id
          name
          color
          type
        }
        description
        isPublic
      }
      notes
    }
  }
`

export const getContractsQuery = () => `
  contracts(
    first: 50
    orderBy: [
      {
        column: NAME,
        order: ASC
      }
    ]
  ) {
    edges {
      node {
        id
        number
        name
        status {
          id
          name
          color
          type
        }
        type {
          name
        }
      }
      notes
    }
  }
`

export const getInterrelationsQuery = () => `
  ${getCompaniesInterrelationsQuery()}
  ${getPeopleInterrelationsQuery()}
`

export const getInterrelationQuery = (type) =>
  type === 'companies' ? getCompaniesInterrelationsQuery() : getPeopleInterrelationsQuery()

export const getConditionalSelectPayload = (type) => `
  id
  ${['contacts', 'clients'].includes(type) ? 'type' : ''}
  displayName
  ${getDefaultPhoneQuery()}
  ${getDefaultEmailQuery()}
  ${getDefaultWebsiteQuery()}
`

export const getContactsQuery = () => `
  contacts(
    first: 50
    orderBy: [
      {
        column: DISPLAY_NAME,
        order: ASC
      }
    ]
  ) {
    pageInfo {
      total
    }
    edges {
      node {
        id
        type
        displayName
        ${getAddressQuery()}
        ${getDefaultPhoneQuery()}
        ${getDefaultEmailQuery()}
        ${getDefaultWebsiteQuery()}
      }
    }
  }
`
