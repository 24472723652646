const actions = {
  SET_STATE: 'notifications/SET_STATE',
  RESET_STATE: 'notifications/RESET_STATE',
  LOAD_METADATA: 'notifications/LOAD_METADATA',
  SET_METADATA: 'notifications/SET_METADATA',
  SET_UNSEEN: 'notifications/SET_UNSEEN',
  MARK_SEEN: 'notifications/MARK_SEEN',
  MARK_ALL_AS_READ: 'notifications/MARK_ALL_AS_READ',
  MARK_AS_READ: 'notifications/MARK_AS_READ',
  MARK_AS_UNREAD: 'notifications/MARK_AS_UNREAD',
  LOAD_DATA: 'notifications/LOAD_DATA',
  SET_ROWS: 'notifications/SET_ROWS',
  APPEND_ROWS: 'notifications/APPEND_ROWS',
  UPDATE_ROW: 'notifications/UPDATE_ROW',
  UPDATE_UNSEEN_ROW: 'notifications/UPDATE_UNSEEN_ROW',
  SET_PAGINATION: 'notifications/SET_PAGINATION',
  RESET_ROWS_STATE: 'notifications/RESET_ROWS_STATE',
  SET_LOADING: 'notifications/SET_LOADING',
  RELOAD_UNSEEN_NOTIFICATIONS: 'notifications/RELOAD_UNSEEN_NOTIFICATIONS',
  SET_UNSEEN_ROWS: 'notifications/SET_UNSEEN_ROWS',
  SET_UNSEEN_PAGINATION: 'notifications/SET_UNSEEN_PAGINATION',
}

export default actions
