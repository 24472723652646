import React from 'react'
import styles from './styles.module.scss'

export default ({ resetError }) => (
  <div className={styles.container}>
    <h1>Unfortunately, something has gone wrong</h1>

    <p>
      We&apos;re unable to fulfill your request. Rest assured we have been notified and are looking
      into the issue.
    </p>

    <p>
      You can try to{' '}
      <a
        role="button"
        onClick={() => {
          resetError()
        }}
        onKeyDown={() => {
          resetError()
        }}
        tabIndex={-1}
      >
        reset the page
      </a>
      , refresh your browser or{' '}
      <a href="/" tabIndex={0}>
        return to the homepage
      </a>
      .
    </p>

    {/* <p>
      If the error continues, please contact our{' '}
      <a href="http://mcause.us/supportticket">support team</a>.
      </p> */}
  </div>
)
