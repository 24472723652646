const actions = {
  SET_STATE: 'taskTypes/SET_STATE',
  RESET_STATE: 'taskTypes/RESET_STATE',
  LOAD_TYPES: 'taskTypes/LOAD_TYPES',
  SET_TYPES: 'taskTypes/SET_TYPES',
  ADD_TYPE: 'taskTypes/ADD_TYPE',
  UPDATE_TYPE: 'taskTypes/UPDATE_TYPE',
}

export default actions
