import { convert } from 'html-to-text'

/**
 * Capitalize the first letter of the string.
 */
export const ucFirst = (string) => string?.charAt(0)?.toUpperCase() + string?.slice(1)

/**
 * Capitalize the whole string.
 */
export const uppercase = (string) => string?.toUpperCase()

/**
 * Lowercase the whole string.
 */
export const lowercase = (string) => (typeof string === 'string' ? string.toLowerCase() : string)

export const lowerFirstLetter = (string) => string.charAt(0).toLowerCase() + string.slice(1)

export const convertHtmlToPlainText = ({ text, options = {} }) => convert(text, options)

export const getHtmlTextSnippet = (text) =>
  convertHtmlToPlainText({
    text,
    options: {
      wordwrap: null,
      limits: {
        maxChildNodes: 5,
        maxDepth: 5,
      },
    },
  })
