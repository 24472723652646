const actions = {
  SET_STATE: 'specifications/SET_STATE',
  RESET_STATE: 'specifications/RESET_STATE',
  SET_PARAMETER_ITEMS: 'specifications/SET_PARAMETER_ITEMS',
  LOAD_PARAMETER_ITEMS: 'specifications/LOAD_PARAMETER_ITEMS',
  ADD_PARAMETER_ITEM: 'specifications/ADD_PARAMETER_ITEM',
  UPDATE_PARAMETER_ITEM: 'specifications/UPDATE_PARAMETER_ITEM',
  REMOVE_PARAMETER_ITEM: 'specifications/REMOVE_PARAMETER_ITEM',
}

export default actions
