import { BrowserTracing } from '@sentry/tracing'

export default {
  dsn: 'https://1fad17d4bd4e4dbe90a4109662b1ba73@o539695.ingest.sentry.io/6515800',
  integrations: [new BrowserTracing()],
  enabled: process.env.NODE_ENV === 'production',
  release: `mailabl-outlook@${window.REACT_APP_VERSION}`,
  environment: process.env.NODE_ENV,
  debug: false,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,

  // To send a representative sample of your errors to Sentry, set the sampleRate option
  // in your SDK configuration to a number between 0 (0% of errors sent) and 1 (100% of errors sent).
  // This is a static rate, which will apply equally to all errors.
  // sampleRate: 0.25

  ignoreErrors: ['ResizeObserver loop limit exceeded'],
}
