import { all, call, put, takeEvery } from 'redux-saga/effects'
import { fetchUsers } from 'services/users/fetch'
import actions from './actions'

/**
 * Load users list.
 */
export function* loadUsers() {
  try {
    const usersResponse = yield call(fetchUsers)

    yield put({
      type: actions.SET_USERS,
      payload: usersResponse,
    })
  } catch (e) {
    yield put({ type: 'GET_USERS_FAILED', message: e.message })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_USERS, loadUsers)])
}
