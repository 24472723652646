const actions = {
  SET_STATE: 'statuses/SET_STATE',
  RESET_STATE: 'statuses/RESET_STATE',
  LOAD_STATUSES: 'statuses/LOAD_STATUSES',
  SET_STATUSES: 'statuses/SET_STATUSES',
  LOAD_MODULES: 'statuses/LOAD_MODULES',
  SET_MODULES: 'statuses/SET_MODULES',
  ADD_STATUS: 'statuses/ADD_STATUS',
  UPDATE_STATUS: 'statuses/UPDATE_STATUS',
  REMOVE_STATUS: 'statuses/REMOVE_STATUS',
}

export default actions
