const actions = {
  SET_STATE: 'contracts/SET_STATE',
  RESET_STATE: 'contracts/RESET_STATE',
  SET_PARAMETER_ITEMS: 'contracts/SET_PARAMETER_ITEMS',
  LOAD_PARAMETER_ITEMS: 'contracts/LOAD_PARAMETER_ITEMS',
  ADD_PARAMETER_ITEM: 'contracts/ADD_PARAMETER_ITEM',
  UPDATE_PARAMETER_ITEM: 'contracts/UPDATE_PARAMETER_ITEM',
  REMOVE_PARAMETER_ITEM: 'contracts/REMOVE_PARAMETER_ITEM',
}

export default actions
