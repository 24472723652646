// eslint-disable-next-line import/prefer-default-export
export const getQueryFields = (path) => {
  const initialQueryFields = {
    main: [],
    child: [],
    pivot: [],
  }

  const {
    getColumns,
    getAdditionalQueryFields,
    // eslint-disable-next-line global-require, import/no-dynamic-require
  } = require(`services/middle/${path}`)

  const columns = getColumns()
  const additionalQueryFields = getAdditionalQueryFields()
  const data = { ...initialQueryFields }

  columns?.forEach((column) => {
    if (typeof column === 'string') {
      data.main.push(column)
    }

    if (typeof column === 'object') {
      const { type, field, subQueryFields } = column

      if (subQueryFields) {
        data[type].push(`
          ${field} {
            ${subQueryFields.join('\n')}
          }
        `)
      } else {
        data[type].push(field)
      }
    }
  })

  if (additionalQueryFields?.length) {
    additionalQueryFields.forEach(({ type, fields: additionalFields }) => {
      data[type].push(additionalFields)
    })
  }

  return data
}
