import { FetchAllData, RunQuery } from 'services/apollo/helpers'

export async function fetchStatuses(objectType) {
  const payload = {
    endpoint: 'statuses',
    fields: ['id', 'name', 'color', 'description', 'type', 'isDefault', 'sortOrder'],
  }

  if (objectType) {
    payload.whereConditionsName = 'QueryStatusesWhereWhereConditions'
    payload.where = {
      column: 'MODULE',
      value: objectType,
    }
  }

  const response = await FetchAllData(payload)

  return response
}

export async function fetchStatusModules() {
  const response = await RunQuery({
    queryName: 'statusModules',
  })

  return response
}
