import { InteractionStatus } from '@azure/msal-browser'
import { MsalAuthenticationTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react'
import * as Sentry from '@sentry/react'
import NProgress from 'nprogress'
import GraphLoginPage from 'pages/auth/graph/login'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { getAuthorizationChecked, getGraphEmail } from 'redux/user/selectors'
import { loginRequest } from 'services/graph/config'
import { getInteractionType, isOnlinePlatform } from 'services/office'
import AuthLayout from './Auth'
import MainLayout from './Main'

const Layouts = {
  auth: AuthLayout,
  main: MainLayout,
}

const mapStateToProps = ({ user }) => ({ user })
let previousPath = ''

const Layout = ({ user, children, intl }) => {
  const { pathname, search } = useLocation()
  const isGraphAuthenticated = useIsAuthenticated()
  const { inProgress } = useMsal()
  const dispatch = useDispatch()

  const graphEmail = useSelector(getGraphEmail)

  const isOnline = isOnlinePlatform()

  const authorizationChecked = useSelector(getAuthorizationChecked)

  const currentPath = pathname + search

  NProgress.configure({
    template: `
      <div class="bar" role="bar">
        <div class="peg"></div>
      </div>

      <div class="spinner !flex !bg-white rounded-md !start-1/2 !end-auto py-2 px-4 text-sm text-neutral-700" role="spinner">
        <div class="spinner-icon me-3"></div>
        ${intl.formatMessage({ id: 'loading' })}
      </div>
    `,
  })

  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    NProgress.start()
  }

  if (authorizationChecked) {
    setTimeout(() => {
      NProgress.done()
      previousPath = currentPath
    }, 300)
  }

  // Layout Rendering
  const getLayout = () => {
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      return 'auth'
    }

    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = user.authorized
  const isUserLoading = user.loading
  const isAuthLayout = getLayout() === 'auth'

  const ErrorComponent = ({ error }) => {
    console.error(error)
    Sentry.captureException(error)

    return <p>{intl.formatMessage({ id: 'login.graph.error' })}</p>
  }

  const LoadingComponent = () => {
    if (!isOnline && inProgress !== InteractionStatus.None) {
      return <p>{intl.formatMessage({ id: 'login.graph.inProgress' })}</p>
    }

    if (isOnline) {
      return <GraphLoginPage />
    }

    return null
  }

  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isAuthLayout && !isUserAuthorized) {
      return <Navigate to="/auth/login" />
    }

    if (isUserAuthorized && !isGraphAuthenticated) {
      return (
        <Container>
          <MsalAuthenticationTemplate
            interactionType={getInteractionType()}
            authenticationRequest={loginRequest}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
          >
            {children}
          </MsalAuthenticationTemplate>
        </Container>
      )
    }

    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  useEffect(() => {
    let mounted = true

    if (mounted && authorizationChecked) {
      NProgress.done()
    }

    return () => {
      mounted = false
    }
  }, [authorizationChecked])

  useEffect(() => {
    let mounted = true

    if (mounted && !graphEmail) {
      dispatch({
        type: 'user/LOAD_GRAPH_DATA',
      })
    }

    return () => {
      mounted = false
    }
  }, [dispatch, graphEmail])

  return (
    <>
      <Helmet titleTemplate="%s - Mailabl" />
      {BootstrappedLayout()}
    </>
  )
}

export default injectIntl(connect(mapStateToProps)(Layout))
