// eslint-disable-next-line import/prefer-default-export
export const getAddressQuery = () => `
  address {
    line1
    line2
    city
    state
    postalCode
    country {
      code
      displayName
    }
  }
`
