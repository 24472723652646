import { cloneDeep } from 'lodash'
import { all, put, select, takeEvery } from 'redux-saga/effects'
import actions from './actions'

export function* UPDATE_STATUS({ payload: { field, status } }) {
  const entries = yield select(({ editors: { editors: data } }) => data)
  const updatedData = cloneDeep(entries)?.map((item) => {
    if (item.id === field) {
      item.status = status

      if (status === 'saved') {
        item.timestamp = new Date()
      }
    }

    return item
  })

  yield put({
    type: actions.SET_STATE,
    payload: { editors: updatedData },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.UPDATE_STATUS, UPDATE_STATUS)])
}
