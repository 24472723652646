const actions = {
  SET_STATE: 'easements/SET_STATE',
  RESET_STATE: 'easements/RESET_STATE',
  SET_PARAMETER_ITEMS: 'easements/SET_PARAMETER_ITEMS',
  LOAD_PARAMETER_ITEMS: 'easements/LOAD_PARAMETER_ITEMS',
  ADD_PARAMETER_ITEM: 'easements/ADD_PARAMETER_ITEM',
  UPDATE_PARAMETER_ITEM: 'easements/UPDATE_PARAMETER_ITEM',
  REMOVE_PARAMETER_ITEM: 'easements/REMOVE_PARAMETER_ITEM',
}

export default actions
