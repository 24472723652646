import React, { lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { HistoryRouter as BaseRouter } from 'redux-first-history/rr6'
import { history } from 'store.js'

import Layout from 'layouts'

const basicRoutes = [
  {
    path: '/',
    Element: lazy(() => import('pages/taskpane')),
    exact: true,
  },
  {
    path: '/taskpane',
    Element: lazy(() => import('pages/taskpane')),
    exact: true,
  },
  {
    path: '/dialog',
    Element: lazy(() => import('pages/dialog')),
    exact: true,
  },
  {
    path: '/auth/login',
    Element: lazy(() => import('pages/auth/login')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const RouterComponent = ({ routerAnimation }) => (
  <BaseRouter history={history}>
    <Layout>
      <Routes>
        {basicRoutes.map(({ path, Element, exact }) => (
          <Route
            path={path}
            key={path}
            exact={exact}
            element={
              <div className={routerAnimation}>
                <Suspense fallback={null}>
                  <SwitchTransition>
                    <CSSTransition
                      key={path}
                      appear
                      classNames={routerAnimation}
                      timeout={routerAnimation === 'none' ? 0 : 300}
                    >
                      <Element />
                    </CSSTransition>
                  </SwitchTransition>
                </Suspense>
              </div>
            }
          />
        ))}
      </Routes>
    </Layout>
  </BaseRouter>
)

export default connect(mapStateToProps)(RouterComponent)
