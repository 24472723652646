import { mapKeys } from 'lodash'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import ListActions from 'redux/list/actions'
import ability from 'services/abilities'
import {
  DeleteEntity,
  FetchAllData,
  FetchEntity,
  UpdatePriority,
  UpdateStatus,
} from 'services/apollo/helpers'
import { getMessageText } from 'services/helpers/message'
import { ucFirst } from 'services/helpers/text'
import { history } from 'store.js'
import actions from './actions'

export function* LOAD_ENTITY({ payload: { id, endpoint, objectType, fields } }) {
  const data = yield call(FetchEntity, {
    endpoint,
    id,
    fields,
  })

  const canRead = ability.can('read', data)

  if (data && canRead) {
    yield put({
      type: actions.SET_ENTITY,
      payload: {
        data,
        objectType,
      },
    })
  } else {
    yield put({
      type: 'view/SET_ENTITY_MISSING',
    })
  }

  yield put({
    type: 'view/SET_DATA_LOADED',
  })
}

export function* LOAD_ENTITIES({ payload: { endpoint, objectType, fields, orderBy, where } }) {
  const response = yield call(FetchAllData, {
    endpoint,
    fields,
    whereConditionsName: `Query${ucFirst(endpoint)}WhereWhereConditions`,
    orderByConditionsName: `[Query${ucFirst(endpoint)}OrderByOrderByClause!]`,
    where,
    orderBy: orderBy || [
      {
        column: 'DISPLAY_NAME',
        order: 'ASC',
      },
    ],
  })

  const data = yield response?.map(({ node: item }) => item)

  if (data) {
    yield put({
      type: actions.SET_ENTITIES,
      payload: {
        data,
        objectType,
      },
    })
  } else {
    yield put({
      type: 'view/SET_ENTITIES_MISSING',
    })
  }

  yield put({
    type: 'view/SET_DATA_LOADED',
  })
}

export function* DELETE_ENTITY({
  payload: {
    id,
    endpoint,
    objectType,
    redirect = false,
    showMessage = false,
    intl,
    message,
    ...rest
  },
}) {
  const response = yield call(DeleteEntity, {
    id,
    endpoint,
  })

  if (response === true) {
    yield put({
      type: actions.DELETE_SUCCESS,
      payload: {
        id,
        objectType,
      },
    })

    yield put({
      type: ListActions.DELETE_SUCCESS,
      payload: {
        id,
        deleteRow: true,
        ...rest,
      },
    })

    if (showMessage) {
      message.success(
        getMessageText({
          action: 'delete',
          text: objectType,
          intl,
        }),
      )
    }

    if (redirect === true) {
      const url = yield select(
        ({ menu }) => menu.menuData.filter((module) => module.key === objectType)?.[0]?.url,
      )

      if (url) {
        yield history.push(url)
      }
    }
  }
}

export function* SET_ENTITY({ payload: { data, objectType } }) {
  const prevObjectData = yield select(({ entities }) => entities[objectType])

  yield put({
    type: actions.SET_STATE,
    payload: {
      [objectType]: {
        ...prevObjectData,
        ...{
          [data.id]: data,
        },
      },
    },
  })
}

export function* SET_ENTITIES({ payload: { data, objectType } }) {
  const dataPayload = mapKeys(data, (value) => value.id)

  yield put({
    type: actions.SET_STATE,
    payload: {
      [objectType]: dataPayload,
    },
  })
}

export function* UPDATE_ENTITY_VALUE({ payload: { data, id, objectType, origin } }) {
  const prevObjectData = yield select(({ entities }) => entities[objectType])
  const prevData = prevObjectData?.[id]

  yield put({
    type: actions.SET_STATE,
    payload: {
      [objectType]: {
        ...prevObjectData,
        ...{
          [id]: { ...prevData, ...data },
        },
      },
    },
  })

  if (origin === 'list') {
    yield put({
      type: ListActions.UPDATE_ROW,
      payload: data,
    })
  }
}

export function* UPDATE_STATUS({ payload: { endpoint, objectType, id, status, intl, message } }) {
  const response = yield call(UpdateStatus, {
    id,
    endpoint,
    status,
  })

  yield put({
    type: actions.UPDATE_ENTITY_VALUE,
    payload: {
      data: response,
      id,
      objectType,
      origin: 'list',
    },
  })

  message.success(
    getMessageText({
      text: 'status',
      intl,
    }),
  )
}

export function* UPDATE_PRIORITY({
  payload: { endpoint, objectType, id, priority, intl, message },
}) {
  const response = yield call(UpdatePriority, {
    id,
    endpoint,
    priority,
  })

  yield put({
    type: actions.UPDATE_ENTITY_VALUE,
    payload: {
      data: response,
      id,
      objectType,
      origin: 'list',
    },
  })

  message.success(
    getMessageText({
      text: 'priority',
      intl,
    }),
  )
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_ENTITY, LOAD_ENTITY),
    takeEvery(actions.LOAD_ENTITIES, LOAD_ENTITIES),
    takeEvery(actions.DELETE_ENTITY, DELETE_ENTITY),
    takeEvery(actions.SET_ENTITY, SET_ENTITY),
    takeEvery(actions.SET_ENTITIES, SET_ENTITIES),
    takeEvery(actions.UPDATE_ENTITY_VALUE, UPDATE_ENTITY_VALUE),
    takeEvery(actions.UPDATE_STATUS, UPDATE_STATUS),
    takeEvery(actions.UPDATE_PRIORITY, UPDATE_PRIORITY),
  ])
}
