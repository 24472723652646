import { getInteractionType } from 'services/office'
import client from './client'
import { loginRequest } from './config'
import instance from './instance'

export const getClient = (endpoint) => {
  const clientPayload = {
    ...loginRequest,
    ...{
      account: instance.getActiveAccount(),
      interactionType: getInteractionType(),
    },
  }

  return client(clientPayload).api(endpoint)
}

export const callApi = async ({
  type = 'get',
  endpoint,
  payload,
  version,
  select,
  expand,
  headers,
}) => {
  let call = getClient(endpoint)
  let result = null

  if (version) {
    call = call.version(version)
  }

  if (select) {
    call = call.select(select)
  }

  if (expand) {
    call = call.expand(expand)
  }

  if (headers && headers?.length) {
    headers.forEach(({ key, value }) => {
      call.header(key, value)
    })
  }

  if (type === 'get') {
    result = await call
      .get()
      .then((response) => response)
      .catch((error) => console.log(error))
  }

  if (type === 'post') {
    result = await call
      .post(payload)
      .then((response) => response)
      .catch((error) => console.log(error))
  }

  return result
}

export const get = async (props) => {
  const result = await callApi(props)

  return result
}

export const post = async (props) => {
  const result = await callApi({ ...props, ...{ type: 'post' } })

  return result
}

export const getImmutableId = async (id) => {
  const response = await post({
    endpoint: '/me/translateExchangeIds',
    payload: {
      inputIds: [id],
      sourceIdType: 'restId',
      targetIdType: 'restImmutableEntryId',
    },
  })

  return response?.value?.[0]?.targetId
}

export const getMe = async () => {
  const response = await get({ endpoint: `/me` })

  return response
}

export const getMessage = async (id) => {
  const response = await get({
    endpoint: `/me/messages/${id}`,
    expand: "singleValueExtendedProperties($filter=id eq 'String 0x0076')", // for getting the "PidTagReceivedByEmailAddress Canonical Property" header
  })

  return response
}

export const getMessageBodyForSummaryGenerator = async (id) => {
  const response = await get({
    endpoint: `/me/messages/${id}`,
    headers: [{ key: 'Prefer', value: 'outlook.body-content-type="text"' }],
    select: 'uniqueBody',
  })

  return response
}

export const getMessageDirection = (message, graphEmail) => {
  if (message?.sender.emailAddress.address === graphEmail) {
    return 'OUTGOING'
  }

  return !!message?.singleValueExtendedProperties?.filter(({ id }) => id === 'String 0x76')
    ?.length !== true
    ? 'OUTGOING'
    : 'INCOMING'
}

export const getMessageMimeContents = async (immutableId) => {
  const response = await get({ endpoint: `/me/messages/${immutableId}/$value` })

  return response
}

export const getAttachmentContents = async (messageId, attachmentId) => {
  const response = await get({
    endpoint: `/me/messages/${messageId}/attachments/${attachmentId}/$value`,
  })

  return response
}

export const getMessageAttachments = async (immutableId) => {
  const response = await get({ endpoint: `/me/messages/${immutableId}/attachments` })

  return response?.value
}
