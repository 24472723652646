import { RunQuery } from 'services/apollo/helpers'

// eslint-disable-next-line import/prefer-default-export
export async function fetchSetting(settings) {
  const aliases = {}

  let isSingleMode = false

  if (!Array.isArray(settings)) {
    settings = [settings]

    isSingleMode = true
  }

  const payload = settings
    ?.map((setting) => {
      const alias = setting.replace('.', '')

      aliases[setting] = alias

      return `
          ${alias}: globalSetting(name: "${setting}") {
            value
          }`
    })
    .join('\n')

  const response = await RunQuery({
    queryName: 'me',
    fields: [payload],
  })

  if (response) {
    if (isSingleMode) {
      return response[aliases[settings[0]]]?.value
    }
  }

  return true
}
