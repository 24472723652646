import { useEffect } from 'react'
import { connect } from 'react-redux'

import 'index.css'

// third-party plugins styles
import 'react-perfect-scrollbar/dist/css/styles.css'

// Custom styles
// import './css/layout.scss'
// import './css/table.scss'
// import './css/tabs.scss'
import './css/avatar.scss'
import './css/buttons.scss'
import './css/datePicker.scss'
import './css/form.scss'
// import './css/list.scss'
// import './css/modal.scss'
import './css/popover.scss'
// import './css/switch.scss'
// import './css/radio.scss'
// import './css/drawer.scss'
import './css/dropdown.scss'
import './css/icons.scss'
import './css/message.scss'
import './css/nprogress.scss'
import './css/select.scss'
import './css/typography.scss'

const mapStateToProps = ({ settings: { theme } }) => ({
  theme,
})

let isLoaded = false

const StylesLoader = ({ dispatch, children, theme }) => {
  useEffect(() => {
    dispatch({
      type: 'settings/SET_THEME',
      payload: {
        theme,
      },
    })

    if (isLoaded) {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'menuColor',
          value: 'white',
        },
      })
    }

    isLoaded = true
  }, [theme, dispatch])

  return children
}

export default connect(mapStateToProps)(StylesLoader)
