const actions = {
  SET_STATE: 'taskpane/SET_STATE',
  RESET_STATE: 'taskpane/RESET_STATE',
  SET_CONTACT: 'taskpane/SET_CONTACT',
  SET_CONTACT_OPTIONS: 'taskpane/SET_CONTACT_OPTIONS',
  SET_CONTACT_LOADED: 'taskpane/SET_CONTACT_LOADED',
  SET_SHOW_CONTACT_SEARCH: 'taskpane/SET_SHOW_CONTACT_SEARCH',
  SET_SHOW_CONTACT_CREATE: 'taskpane/SET_SHOW_CONTACT_CREATE',
  SET_CONTACT_NOT_FOUND: 'taskpane/SET_CONTACT_NOT_FOUND',
}

export default actions
