const actions = {
  SET_STATE: 'view/SET_STATE',
  RESET_STATE: 'view/RESET_STATE',
  RESET_RELATIONS_STATE: 'view/RESET_RELATIONS_STATE',
  SET_ID: 'view/SET_ID',
  SET_FIELDS_LOADED: 'view/SET_FIELDS_LOADED',
  SET_DATA_LOADED: 'view/SET_DATA_LOADED',
  SET_ENTITY_MISSING: 'view/SET_ENTITY_MISSING',
  SET_ENTITIES_MISSING: 'view/SET_ENTITIES_MISSING',
  SET_OBJECT_TYPE: 'view/SET_OBJECT_TYPE',
  SET_ENDPOINT: 'view/SET_ENDPOINT',
  SET_PATH_DETAILS: 'view/SET_PATH_DETAILS',
  UPDATE_ENTITY_VALUE: 'view/UPDATE_ENTITY_VALUE',
  SET_RELATION_DATA: 'view/SET_RELATION_DATA',
  SET_RELATION_ROWS: 'view/SET_RELATION_ROWS',
  SET_RELATION_PAGINATION: 'view/SET_RELATION_PAGINATION',
  SET_RELATION_NEW_END_CURSOR: 'view/SET_RELATION_NEW_END_CURSOR',
  UPDATE_RELATION_ROW: 'view/UPDATE_RELATION_ROW',
  TOGGLE_EDITABLE_RELATION_ROW: 'view/TOGGLE_EDITABLE_RELATION_ROW',
  DELETE_RELATION_ROW: 'view/DELETE_RELATION_ROW',
  SET_RELATION_FIRST_ROW: 'view/SET_RELATION_FIRST_ROW',
  LOAD_COMMENTS: 'view/LOAD_COMMENTS',
  APPEND_COMMENT_REPLY: 'view/APPEND_COMMENT_REPLY',
  RESET_COMMENT_REPLY: 'view/RESET_COMMENT_REPLY',
  SET_ACTIVE_TAB: 'view/SET_ACTIVE_TAB',
}

export default actions
