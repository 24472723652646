export const getColumns = () => [
  {
    title: 'properties.headings.cadastralUnit',
    type: 'child',
    field: 'cadastralUnitNumber',
    width: '30%',
  },
  {
    type: 'child',
    field: 'displayAddress',
    width: '70%',
  },
]

export const getAdditionalQueryFields = () => null

export const getOrderBy = () => [
  {
    column: 'DISPLAY_ADDRESS',
    order: 'ASC',
  },
]
