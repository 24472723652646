import actions from './actions'

const initialState = {
  contact: null,
  contactOptions: null,
  contactSet: false,
  contactLoaded: false,
  showContactSearch: false,
  showContactCreate: false,
  contactNotFound: null,
}

export default function taskpaneReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.RESET_STATE:
      return initialState

    case actions.SET_CONTACT:
      return {
        ...state,
        ...{
          contact: action.payload,
          contactSet: true,
          contactNotFound: false,
        },
      }

    case actions.SET_CONTACT_OPTIONS:
      return {
        ...state,
        ...{
          contactOptions: action.payload,
          contactSet: false,
        },
      }

    case actions.SET_CONTACT_LOADED:
      return {
        ...state,
        ...{
          contactLoaded: action.payload,
        },
      }

    case actions.SET_SHOW_CONTACT_SEARCH:
      return {
        ...state,
        ...{
          showContactSearch: action.payload,
        },
      }

    case actions.SET_SHOW_CONTACT_CREATE:
      return {
        ...state,
        ...{
          showContactCreate: action.payload,
        },
      }

    case actions.SET_CONTACT_NOT_FOUND:
      return {
        ...state,
        ...{
          contactNotFound: action.payload,
          contactLoaded: true,
          contact: null,
          contactSet: false,
        },
      }

    default:
      return state
  }
}
