export const getUnseen = ({ notifications }) => notifications.unseen

export const getUnread = ({ notifications }) => notifications.unread

export const getRows = ({ notifications }) => notifications.rows

export const getPagination = ({ notifications }) => notifications.pagination

export const getEndCursor = ({ notifications }) => notifications.pagination.endCursor

export const getLoading = ({ notifications }) => notifications.loading

export const getUnseenRows = ({ notifications }) => notifications.unseenRows
