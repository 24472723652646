export const getIsAuthorized = ({ user }) => user.authorized === true

export const getAuthorizationChecked = ({ user }) => user.authorizationChecked

export const getId = ({ user }) => user.id

export const getIsAccountOwner = ({ user }) => user.isAccountOwner

export const getModules = ({ user }) => user.modules

export const hasModule =
  (objectType) =>
  ({ user }) =>
    !!user.modules.filter((item) => item.slug?.toLowerCase() === objectType)?.length

export const getAccount = ({ user }) => user.account

export const getGraphData = ({ user }) => user.graphData

export const getGraphEmail = ({ user }) => user?.graphData?.mail
