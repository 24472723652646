import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

export const getSupportedFormats = () => [
  'LL',
  'DD.MM.YYYY',
  'DD.MM.YY',
  'DD/MM/YYYY',
  'DD/MM/YY',
  'YYYY-MM-DD',
]

export const parseISO = (date) => {
  if (!date) {
    return undefined
  }

  if (date instanceof Date) {
    return date
  }

  return dayjs(date)
}

export const format = (date, formatStr = 'LL') =>
  date && (formatStr === 'iso' ? dayjs(date).format() : dayjs(date).format(formatStr))

export const formatDistanceToNow = (date) => dayjs(date).toNow(true)

export const getRangePresets = () => {
  const today = dayjs().startOf('date')
  const todayEnd = today.endOf('date')
  const tomorrowStart = today.add(1, 'day').startOf('date')
  const yesterday = today.subtract(1, 'day')
  const nextWeek = today.add(1, 'week')
  const lastWeek = today.subtract(1, 'week')
  const nextMonth = today.add(1, 'month')
  const lastMonth = today.subtract(1, 'month')
  const nextYear = today.add(1, 'year')
  const lastYear = today.subtract(1, 'year')

  return {
    days: {
      today: [today, todayEnd],
      tomorrow: [tomorrowStart, tomorrowStart.endOf('date')],
      next7Days: [tomorrowStart, todayEnd.add(8, 'day')],
      next14Days: [tomorrowStart, todayEnd.add(15, 'day')],
      next30Days: [tomorrowStart, todayEnd.add(31, 'day')],
      next90Days: [tomorrowStart, todayEnd.add(91, 'day')],
      next365Days: [tomorrowStart, todayEnd.add(366, 'day')],
      yesterday: [yesterday.startOf('date'), yesterday.endOf('date')],
      last7Days: [today.subtract(6, 'day'), todayEnd],
      last14Days: [today.subtract(13, 'day'), todayEnd],
      last30Days: [today.subtract(29, 'day'), todayEnd],
      last90Days: [today.subtract(89, 'day'), todayEnd],
      last365Days: [today.subtract(364, 'day'), todayEnd],
    },
    weeks: {
      thisWeek: [today.startOf('week'), today.endOf('week')],
      nextWeek: [nextWeek.startOf('week'), nextWeek.endOf('week')],
      lastWeek: [lastWeek.startOf('week'), lastWeek.endOf('week')],
      weekToDate: [today.startOf('week'), todayEnd],
    },
    months: {
      thisMonth: [today.startOf('month'), today.endOf('month')],
      nextMonth: [nextMonth.startOf('month'), nextMonth.endOf('month')],
      lastMonth: [lastMonth.startOf('month'), lastMonth.endOf('month')],
      monthToDate: [today.startOf('month'), todayEnd],
    },
    years: {
      thisYear: [today.startOf('year'), today.endOf('year')],
      nextYear: [nextYear.startOf('year'), nextYear.endOf('year')],
      lastYear: [lastYear.startOf('year'), lastYear.endOf('year')],
      yearToDate: [today.startOf('year'), todayEnd],
    },
  }
}
