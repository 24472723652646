import { gql } from '@apollo/client'
import apiClient from 'services/apollo'
import { getMainQuery } from 'services/queries/modules/fields'

export async function getListData(objectType, requestFields) {
  const query = gql`
    query listData(
      ${requestFields ? '$slug: String!' : ''}
      $first: Int
      $viewsWhere: QueryViewsWhereWhereConditions
      $viewsOrderBy: [QueryViewsOrderByOrderByClause!]
    ) {
      ${requestFields ? getMainQuery() : ''}
      views(first: $first, where: $viewsWhere, orderBy: $viewsOrderBy) {
        edges {
          node {
            __typename
            id
            name
            metadata
            isDefault
          }
        }
      }
    }
  `

  const response = await apiClient.query({
    query,
    variables: {
      slug: requestFields ? objectType : undefined,
      first: 1,
      viewsWhere: {
        AND: [
          {
            column: 'MODULE',
            value: objectType,
          },
          {
            column: 'IS_DEFAULT',
            value: true,
          },
        ],
      },
      viewsOrderBy: [
        {
          column: 'IS_DEFAULT',
          order: 'DESC',
        },
      ],
    },
  })

  return response
}

export const getColumnAlignment = ({ type }) => {
  if (['CURRENCY'].includes(type)) {
    return 'right'
  }

  return 'left'
}
