import { LogLevel } from '@azure/msal-browser'

export const msalConfig = {
  auth: {
    clientId: '30a89c31-9c85-4f1e-8b70-4f14861114f6',
    authority: 'https://login.microsoftonline.com/organizations', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: `https://${window.location.host}`,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    secureCookies: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }

        switch (level) {
          case LogLevel.Error:
            console.error(message)
            break

          // case LogLevel.Info:
          //  console.info(message)
          //  break

          case LogLevel.Verbose:
            console.debug(message)
            break

          case LogLevel.Warning:
            console.warn(message)
            break

          default:
        }
      },
    },
  },
}

export const loginRequest = {
  scopes: ['User.Read', 'Mail.Read'],
}
