import { FetchAllData } from 'services/apollo/helpers'
import { getMainQuery } from 'services/queries/users'

/**
 * Fetch users from the backend.
 */
/* eslint-disable import/prefer-default-export */
export async function fetchUsers() {
  const response = await FetchAllData({
    endpoint: 'users',
    orderByConditionsName: `[QueryUsersOrderByOrderByClause!]`,
    orderBy: [
      {
        column: 'DISPLAY_NAME',
        order: 'ASC',
      },
    ],
    fields: [getMainQuery()],
  })

  return response
}
