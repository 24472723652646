export const getAll = ({ view }) => view

export const getId = ({ view }) => view.id

export const getData = ({ entities, view: { id, objectType } }) =>
  entities?.[objectType]?.[id] || {}

export const getFollowers = ({ entities, view: { id, objectType } }) =>
  entities?.[objectType]?.[id]?.followers || []

export const getDataLoaded = ({ view }) => view.dataLoaded

export const getFieldsLoaded = ({ view }) => view.fieldsLoaded

export const getEntityMissing = ({ view }) => view.entityMissing

export const getEntitiesMissing = ({ view }) => view.entitiesMissing

export const getObjectType = ({ view }) => view.objectType

export const getEndpoint = ({ view }) => view.endpoint

export const getRelation =
  (relation) =>
  ({ view }) =>
    view.relations?.[relation]

export const getRelationRows =
  (relation) =>
  ({ view }) =>
    view.relations?.[relation]?.tableRows

export const getEditableRelationRow =
  (relation) =>
  ({ view }) =>
    view.relations?.[relation]?.tableRows?.filter((item) => item?.isEditing === true)?.[0]

export const getRelationPagination =
  (relation) =>
  ({ view }) =>
    view.relationsPagination?.[relation]

export const getRelationNewEndCursor =
  (relation) =>
  ({ view }) =>
    view.relations?.[relation]?.endCursor

export const getComments = ({ view }) => view.comments

export const getCommentsLoaded = ({ view }) => view.commentsLoaded

export const getParentsInitialRelationData =
  (relation) =>
  ({ entities, view: { id, objectType } }) =>
    entities?.[objectType]?.[id]?.[relation]

export const getActiveTab = ({ view }) => view.activeTab
