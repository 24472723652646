import { Layout } from 'antd'
import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getId } from 'redux/user/selectors'

const MainLayout = ({ children }) => {
  const { pathname } = useLocation()
  const modulePath = pathname.split('/')?.[1]

  const userId = useSelector(getId)

  const sendAnalyticsData = () => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.send('pageview')
    }
  }

  sendAnalyticsData()

  useEffect(() => {
    let mounted = true

    if (mounted) {
      ReactGA.set({ userId })
    }

    return () => {
      mounted = false
    }
  }, [userId])

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Layout>
        <Layout.Content key={modulePath} className="px-2 mt-3">
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  )
}

export default MainLayout
