import { App, ConfigProvider } from 'antd'
import Empty from 'components/Empty'
import dayjs from 'dayjs'
import dayjsEn from 'dayjs/locale/en'
import dayjsEt from 'dayjs/locale/et'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import { setCookie } from 'services/helpers/cookies'
import english from './locales/en'
import estonian from './locales/et'
import * as themes from './themes'

const locales = {
  en: { locale: english, dayjs: dayjsEn },
  et: { locale: estonian, dayjs: dayjsEt },
}

const mapStateToProps = ({ settings }) => ({ settings })

const Localization = ({ children, settings: { locale } }) => {
  const currentLocale = locales[locale]

  window.locale = locale

  dayjs.locale(currentLocale.dayjs)

  setCookie('locale', window.locale, 30)

  const handleError = (err) => {
    if (err.code === 'MISSING_TRANSLATION') {
      console.warn('Missing translation', err.message)
      return
    }

    throw err
  }

  const theme = themes.light

  return (
    <ConfigProvider
      locale={currentLocale.locale.localeAntd}
      theme={theme}
      renderEmpty={() => <Empty />}
    >
      <App>
        <IntlProvider
          locale={currentLocale.locale.locale}
          messages={currentLocale.locale.messages}
          onError={handleError}
        >
          {children}
        </IntlProvider>
      </App>
    </ConfigProvider>
  )
}

export default connect(mapStateToProps)(Localization)
