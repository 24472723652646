import { FetchAllData, RunQuery } from 'services/apollo/helpers'

export async function fetchTags(objectType) {
  const payload = {
    endpoint: 'tags',
    fields: ['id', 'name'],
  }

  if (objectType) {
    payload.whereConditionsName = 'QueryTagsWhereWhereConditions'
    payload.where = {
      column: 'MODULE',
      value: objectType,
    }
  }

  const response = await FetchAllData(payload)

  return response
}

export async function fetchTagModules() {
  const response = await RunQuery({
    queryName: 'tagModules',
  })

  return response
}
